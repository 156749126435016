<form class="example-form">
    <mat-form-field class="example-full-width">
        <mat-label>Bundesland Suchen . . .</mat-label>
        <input type="text"
               placeholder="Bundesland Suchen"
               aria-label="Bundesland Suchen"
               matInput
               [formControl]="myControl"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            @for (option of filteredOptions | async; track option) {
                <mat-option [value]="option">{{option}}</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
</form>
