export const SEO_DATA = {
    homepage: {
        title: 'Willkommen bei Palladio - Ihre Quelle für B2B-Leads in Deutschland',
        description: 'Palladio bietet hochwertige B2B-Leads und umfassende Geschäftsdatenlösungen für den deutschen Markt. Steigern Sie Ihre Akquise mit unserer Hilfe.',
        keywords: 'B2B-Leads, Geschäftsdaten Deutschland, Lead-Generierung, Palladio, deutsche Unternehmen',
        ogTitle: 'Palladio - Hochwertige B2B-Leads in Deutschland',
        ogDescription: 'Optimieren Sie Ihre Lead-Akquise mit deutschen Geschäftsdaten von Palladio.'
    },
    scrollViewPage: {
        title: 'B2B-Daten Tabellen durchsuchen und kaufen - Palladio',
        description: 'Durchsuchen Sie unsere umfangreiche Sammlung von B2B-Daten-Tabellen und erwerben Sie die besten Datensätze für Ihre Lead-Generierung in Deutschland.',
        keywords: 'B2B-Daten-Tabellen, B2B-Daten kaufen, Lead-Generierung, deutsche Unternehmen, Daten kaufen',
        ogTitle: 'B2B-Daten Tabellen durchsuchen und kaufen - Palladio',
        ogDescription: 'Entdecken Sie eine Vielzahl von B2B-Daten-Tabellen und kaufen Sie gezielte Datensätze für Ihre Geschäftsentwicklung in Deutschland mit Palladio.'
    },
    generateTablePage: {
        title: 'B2B-Email Tabellen erstellen - Palladio',
        description: 'Erstellen Sie individuelle Tabellen mit B2B-Email-Adressen von Unternehmen in Deutschland und optimieren Sie Ihre Lead-Generierung.',
        keywords: 'B2B-Email-Tabellen, Lead-Generierung, deutsche Unternehmen, B2B-Daten, Email-Adressen',
        ogTitle: 'B2B-Email Tabellen erstellen - Palladio',
        ogDescription: 'Erstellen Sie maßgeschneiderte Tabellen mit B2B-Email-Adressen für gezielte Lead-Generierung in Deutschland mit Palladio.'
    },
    kontaktPage: {
        title: 'Kontaktieren Sie Uns - Palladio',
        description: 'Nehmen Sie Kontakt mit Palladio auf und erfahren Sie mehr über unsere Dienstleistungen für die B2B-Lead-Generierung.',
        keywords: 'Kontakt, Palladio, B2B-Lead-Service, deutsche Geschäftsdaten',
        ogTitle: 'Kontakt - Palladio',
        ogDescription: 'Kontaktieren Sie Palladio, um mehr über unsere B2B-Lösungen in Deutschland zu erfahren.'
    },
    impressumsPage: {
        title: 'Impressum - Palladio',
        description: 'Lesen Sie das Impressum von Palladio und erfahren Sie mehr über unser Unternehmen und die rechtlichen Informationen.',
        keywords: 'Impressum, Palladio, rechtliche Hinweise, Unternehmensinformationen, B2B-Lead-Generierung',
        ogTitle: 'Impressum - Palladio',
        ogDescription: 'Erfahren Sie mehr über Palladio, unsere rechtlichen Informationen und unser Engagement für die B2B-Lead-Generierung in Deutschland.'
    },
    PageNotFound: {
        title: 'Seite nicht gefunden - Palladio',
        description: 'Die angeforderte Seite wurde leider nicht gefunden. Überprüfen Sie die URL oder gehen Sie zurück zur Startseite.',
        keywords: '404, Seite nicht gefunden, Palladio, Fehler, URL',
        ogTitle: '404 - Seite nicht gefunden - Palladio',
        ogDescription: 'Entschuldigung, die Seite, die Sie suchen, konnte nicht gefunden werden. Besuchen Sie unsere Startseite für weitere Informationen.'
    },
    services: {
        title: 'Unsere Dienstleistungen - Palladio',
        description: 'Erfahren Sie mehr über unsere Dienstleistungen zur B2B-Lead-Generierung und lesen Sie Kundenmeinungen.',
        keywords: 'Dienstleistungen, Palladio, B2B-Leads, Geschäftsentwicklung, Kundenmeinungen',
        ogTitle: 'Unsere Dienstleistungen - Palladio',
        ogDescription: 'Entdecken Sie unsere B2B-Dienstleistungen und erfahren Sie, wie Kunden von Palladio profitieren.',
    }
};





