import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import {ScrapingRequest} from '../../../CustomComponents/Objects/SearchResult';
import {Router} from '@angular/router';
import {CheckoutService} from '../../../Services/BuissnessLogic/checkout.service';
import {AuthService} from '../../../Services/BuissnessLogic/auth.service';
import {InteliRouteService} from '../../../Services/inteli-route.service';

@Component({
  selector: 'app-enterprice-sass-work',
  templateUrl: './enterprice-sass-work.component.html',
  styleUrls: ['./enterprice-sass-work.component.scss']
})
export class EnterpriceSassWorkComponent implements OnInit, AfterViewInit {

  public showTemplateError1: boolean = false;
  public showTemplateError2: boolean = false;
  public showTemplateError3: boolean = false;
  public showTemplateError4: boolean = false;

  public selectedIndustry: string = "";
  public industryUserNote: string = "";
  public selectedRegion: string = "";

  public activeTab: string = "";
  public activeTabIndex: number = 0;

  /* Quantity Selector */
  selectedRowCount: number = 1000;

  selectedPaymentIndex: number | null = null;

  content: { title: string; template: TemplateRef<any> }[] = [];

  @ViewChild('template1', { static: true }) template1!: TemplateRef<any>;
  @ViewChild('template2', { static: true }) template2!: TemplateRef<any>;
  @ViewChild('template3', { static: true }) template3!: TemplateRef<any>;
  @ViewChild('template4', { static: true }) template4!: TemplateRef<any>;
  @ViewChild('template5', { static: true }) template5!: TemplateRef<any>;

  nav = [
    { img: 'assets/images/saas1/tab-icon/installation.png', title: 'Branche Auswählen' },
    { img: 'assets/images/saas1/tab-icon/001-tap.png', title: 'Region Auswählen' },
    { img: 'assets/images/saas1/tab-icon/button.png', title: 'Spalten Bearbeiten' },
    { img: 'assets/images/saas1/tab-icon/002-settings.png', title: 'Zeilen Bearbeiten' },
    { img: 'assets/images/saas1/tab-icon/key-1.png', title: 'Bestellen' }
  ];

  // FIXME Externalize this Data
  pricingcarouselOptions2 = {
    items: 3,
    margin: 30,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      576: {
        items: 2,
        margin: 10
      },
      991: {
        items: 2,
        margin: 15
      },
      992: {
        items: 3,
        margin: 15
      },
      1000: {
        items: 3
      }
    }
  };

  pricing2 = [
    {
      icon: 'rocket-ship',
      package: 'Basic',
      feature1: 'Firmen Name',
      feature2: 'Website',
      feature3: 'Telefon Nummer',
      feature4: 'Adresse',
      feature5: 'Postleizahl',
      feature6: '-',
      feature7: '-',
      price: 0.01,
      btn: 'purchase',
      id: 0
    },
    {
      icon: 'diamond',
      package: 'Medium',
      feature1: 'Firmen Name',
      feature2: 'Website',
      feature3: 'Telefon Nummer',
      feature4: 'Adresse',
      feature5: 'Postleizahl',
      feature6: 'Email Adressen',
      feature7: '-',
      price: 0.05,
      btn: 'purchase',
      id: 1
    },
    {
      icon: 'pie-chart',
      package: 'Business',
      feature1: 'Firmen Name',
      feature2: 'Website',
      feature3: 'Telefon Nummer',
      feature4: 'Adresse',
      feature5: 'Postleizahl',
      feature6: 'Email Adressen',
      feature7: 'Name Geschäftsführer',
      price: 0.10,
      btn: 'purchase',
      id: 2
    }
  ];

  /* Region Selector */
  public selected_regions: string[] = [];
  public supported_regions: any[] = [
    'Deutschlandweit',
    'Baden-Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein-Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen'
  ];

  constructor(private cdr: ChangeDetectorRef,
              private router: Router,
              private checkout: CheckoutService,
              private auth: AuthService,
              private intRoute: InteliRouteService
  ) {}

  ngOnInit(): void {
    this.activeTab = this.nav[0].title;
    this.activeTabIndex = 0;
  }

  ngAfterViewInit(): void {
    this.content = [
      { title: 'Branche Auswählen', template: this.template1 },
      { title: 'Region Auswählen', template: this.template2 },
      { title: 'Spalten Bearbeiten', template: this.template3 },
      { title: 'Zeilen Bearbeiten', template: this.template4 },
      { title: 'Bestellen', template: this.template5 },
    ];
    this.cdr.detectChanges();
  }

  onSliderChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.selectedRowCount = parseInt(inputElement.value, 10);
  }


  openNewIndex(index: number) {
    this.activeTabIndex = index;

    if (index <  this.nav.findIndex(item => item.title === this.activeTab)) {
      this.activeTab = this.nav[index].title;
      this.activeTabIndex = index;
      console.log("pass");
      return;
    }

    if (this.areConditionsMetForCurrentTab(index)) {
      this.activeTab = this.nav[index].title;
      this.activeTabIndex = index;
    }
  }

  openWorkSlide(title: string): void {
    const new_index = this.nav.findIndex(item => item.title === title);
    this.openNewIndex(new_index);
  }

  areConditionsMetForCurrentTab(new_index: number): boolean {
    if (this.activeTab === this.nav[0].title) {
      if (this.selectedIndustry === "" || new_index > 1) {
        this.showTemplateError1 = true;
        return false;
      } else {
        this.showTemplateError1 = false;
      }
    }

    if (this.activeTab === this.nav[1].title) {
      if (this.selected_regions.length == 0 || new_index > 2) {
        this.showTemplateError2 = true;
        return false;
      } else {
        this.showTemplateError2 = false;
      }
    }

    if (this.activeTab === this.nav[2].title) {
      if (this.selectedPaymentIndex === null || new_index > 3) {
        this.showTemplateError3 = true;
        return false;
      } else {
        this.showTemplateError3 = false;
      }
    }

    if (this.activeTab === this.nav[3].title) {
      if (new_index > 4) {
        this.showTemplateError4 = true;
        return false;
      } else {
        this.showTemplateError4 = false;
      }
    }

    return true
  }

  /* Region Selector */
  onRegionCheckboxChange(event: any, region: string) {
    // FIXME Add selecting and de Selecting of Deutschlandweit
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      this.selected_regions.push(region);
    } else {
      this.selected_regions = this.selected_regions.filter(r => r !== region);
    }
    console.log(this.selected_regions);
  }

  goToCheckOut() {
    const calc_price:number = this.pricing2[this.selectedPaymentIndex].price
    this.selectedRegion = this.selected_regions.join(',');

    let scrapingRequest: ScrapingRequest = {
      industry: this.selectedIndustry,
      region: this.selectedRegion,
      row_count: this.selectedRowCount,
      note: this.industryUserNote,
      price: calc_price * this.selectedRowCount,
      type: this.selectedPaymentIndex
    };
    console.log(scrapingRequest);
    this.checkout.set_scraping_request(scrapingRequest);

    if (this.auth.isLoggedIn()) {
      this.router.navigate(["/checkout/new_table"]);
    } else {
      // set inteli Route here
      this.intRoute.store_next_route("/checkout/new_table");
      this.router.navigate(["/anmelden"]);
    }
  }

  onFeatureClick(index: number): void {
    this.selectedPaymentIndex = index;
  }

}
