import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SearchResult} from '../../../CustomComponents/Objects/SearchResult';
import {SearchService} from '../../../Services/BuissnessLogic/search.service';
import {ColorScssService} from '../../../shared/service/color-scss.service';
import {SeoService} from '../../../Services/SEO/seo.service';

@Component({
    selector: 'app-ScrollView',
    templateUrl: './scroll-view.html',
    styleUrls: ['./scroll-view.scss']
})
// todo fetch all, sort locally? -> improving server performance
export class ScrollView implements OnInit {
    fetchedSearchResults: SearchResult[] = [];
    displayedSearchResults: SearchResult[] = [];
    industries: Set<string> = new Set<string>();

    public selected_regions: any[] = [];
    public supported_regions: any[] = [
        'Deutschlandweit',
        'Baden-Württemberg',
        'Bayern',
        'Berlin',
        'Brandenburg',
        'Bremen',
        'Hamburg',
        'Hessen',
        'Mecklenburg-Vorpommern',
        'Niedersachsen',
        'Nordrhein-Westfalen',
        'Rheinland-Pfalz',
        'Saarland',
        'Sachsen',
        'Sachsen-Anhalt',
        'Schleswig-Holstein',
        'Thüringen'
    ];

    public isEmpty: boolean = true;

    constructor(
        private searchService: SearchService,
        private color: ColorScssService,
        private seoService: SeoService,
    ) {
        color.resetColorScheme()
    }

    ngOnInit() {
        this.seoService.setMetaData("scrollViewPage");
        this.fetchSearchResults();
    }

    onDropdownValueChangedIndustry(selectedValue: string) {
        if (selectedValue === '') {
            this.displayedSearchResults = this.fetchedSearchResults;
            return;
        }
        // Apply the filter to get only results where the industry matches the selected value, case-insensitive
        const filteredResults = this.displayedSearchResults.filter(result =>
            result.industry && result.industry.toLowerCase() === selectedValue.toLowerCase()
        );
        this.displayedSearchResults = filteredResults;
        console.log(filteredResults);
    }

    // todo check for undefined values
    // todo add the Search Box Behavior
    fetchSearchResults() {
        this.searchService.fetchSearchResults(this.selected_regions).subscribe(
            (response) => {
                this.fetchedSearchResults = response.data;
                this.displayedSearchResults = response.data;

                console.log('Search results retrieved:', this.fetchedSearchResults);
                console.log('Response message:', response.message);
                this.filter_industries();
            },
            (error) => {
                console.error('Error retrieving search results:', error);
            }
        );
    }

    public updateTagFilters(tags: any[]) {
        this.selected_regions = tags;
        console.log(this.selected_regions);
        this.fetchSearchResults();
    }

    // Method to handle checkbox changes
    onRegionCheckboxChange(event: any, region: string) {
        if (event.target.checked) {
            this.selected_regions.push(region);
        } else {
            // If checkbox is unchecked, remove the region from selectedRegions
            this.selected_regions = this.selected_regions.filter(r => r !== region);
        }

        // Trigger your update function with the selected regions
        this.updateTagFilters(this.selected_regions);
    }

    filter_industries() {
        this.industries = new Set<string>();
        for (let result of this.fetchedSearchResults) {
            this.industries.add(result.industry);  // Adds only unique industries to the Set
        }
        console.log(this.industries);
    }
}
