<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain ">
                    <div>
                        <h2 class="breadcrumb-txt">Produkt Ansicht</h2>
                    </div>
                    <div>
                        <ul>
                            <li><a href="#">Leads</a></li>
                            <li><a href="#"><i class="fa fa-angle-double-right"></i>{{ searchResult.table_name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="agency">
    <section>
        <div class="collection-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xs-12">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-10">
                                    <div class="product-right pro_sticky_info">
                                        <h2>{{ searchResult.industry }}</h2>
                                        <h4>
                                            <span>22€</span>
                                        </h4>
                                        <h5>{{ searchResult.row_count + " Zeilen" }}</h5>
                                        <h5>{{ "Bundsland: " + searchResult.region}}</h5>

                                        <div class="product-description border-product">

                                            <!-- Just for the Spacing -->
                                        </div>
                                        <div class="product-buttons">
                                            <a (click)="proceed_to_checkout()"
                                               class="btn btn-default primary-btn radius-0"
                                            >Jetzt Kaufen</a>
                                        </div>
                                        <div class="border-product">
                                            <h6 class="product-title">Lead-Liste {{ table_name}} – Ihre Lösung für gezielte B2B-Akquise</h6>
                                            <p>
                                                <br>
                                                Erwerben Sie die aktuelle Lead-Liste {{ table_name }} und erhalten Sie alle relevanten Unternehmensdaten <br>
                                                aus dem Bereich {{searchResult.industry}} im Bundesland {{searchResult.region}} .<br>
                                                Diese Liste wurde speziell für Unternehmen entwickelt, die gezielt Geschäftskontakte aufbauen und expandieren möchten. <br>

                                                Aktualisiert am: {{searchResult.age}} <br>

                                                Die Liste umfasst folgende Spalten:
                                                <br>
                                                <br>
                                                Firmen Name <br>
                                                Adresse <br>
                                                Postleizahl <br>
                                                Website <br>
                                                Email Adressen <br>
                                                Telefon Nummer <br>
                                                <br>
                                                <br>
                                                Profitieren Sie von detaillierten und aktuellen Informationen, um Ihre Akquise effizient und zielgerichtet zu gestalten.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 rtl-text">
                                </div>
                            </div>
                        </div>
                        <!-- also add description: number of lines, content -->

                        <!-- Example Image View -->
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="top-banner-wrapper">
                                        <a>
                                            <!-- Enable when Pictures are Setup
                                            <img [src]="imageUrl" alt="Image" />
                                            -->
                                            <img src="../../../../assets/images/inner-page/banner.jpg" class="img-fluid"
                                                 alt="">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section class="tab-product m-0 p-b-0">
                            <div class="row">
                                <div class="col-sm-12 col-lg-12">
                                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">

                                        <!--
                                        <li [ngbNavItem]="1">
                                            <button ngbNavLink>Beschreibung</button>
                                            <ng-template ngbNavContent>
                                                <p class="p-b-0">Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting industry. Lorem Ipsum has been the industry's standard
                                                    dummy
                                                    text ever since the 1500s, when an unknown printer took a galley of
                                                    type and
                                                    scrambled it to make a type specimen book. It has survived not only
                                                    five
                                                    centuries, but also the leap into electronic typesetting, remaining
                                                    essentially unchanged. It was popularised in the 1960s with the
                                                    release of
                                                    Letraset sheets containing Lorem Ipsum passages, and more recently
                                                    with
                                                    desktop publishing software like Aldus PageMaker including versions
                                                    of Lorem
                                                    Ipsum. Lorem Ipsum is simply dummy text of the printing and
                                                    typesetting
                                                    industry. Lorem Ipsum has been the industry's standard dummy text
                                                    ever since
                                                    the 1500s, when an unknown printer took a galley of type and
                                                    scrambled it to
                                                    make a type specimen book. It has survived not only five centuries,
                                                    but also
                                                    the leap into electronic typesetting, remaining essentially
                                                    unchanged. It
                                                    was popularised in the 1960s with the release of Letraset sheets
                                                    containing
                                                    Lorem Ipsum passages, and more recently with desktop publishing
                                                    software
                                                    like Aldus PageMaker including versions of Lorem Ipsum.
                                                </p>
                                            </ng-template>
                                        </li>
                                        -->
                                        <li [ngbNavItem]="2">
                                            <button ngbNavLink>Inhalt</button>
                                            <ng-template ngbNavContent>
                                                <div class="single-product-tables">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td>Branche</td>
                                                            <td>{{ searchResult.industry }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Region</td>
                                                            <td>{{ searchResult.region }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Zeilenanzahl</td>
                                                            <td>{{ searchResult.row_count }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Alter</td>
                                                            <td>{{ searchResult.age }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Zeilenanzahl</td>
                                                            <td>{{ searchResult.row_count }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td>Firmen Name:</td>
                                                            <td>Name der Firma</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Adresse:</td>
                                                            <td>Addresse des Geschäftssitzes</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Postleizahl:</td>
                                                            <td>Postleizahl des Unternehmens</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Website:</td>
                                                            <td>Offizielle Website des Unternehmens</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email Adressen:</td>
                                                            <td>Email Adresse(n) des Unternehmens</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Telefon Nummer:</td>
                                                            <td>Offizielle Telefon Nr des Unternehmens</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ng-template>
                                        </li>
                                    </ul>
                                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
