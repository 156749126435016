import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactSendService} from '../Services/BuissnessLogic/contact-send.service';
import {ColorScssService} from '../shared/service/color-scss.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    contactForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        companyName: new FormControl('', Validators.required),
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        message: new FormControl('', Validators.required)
    });

    constructor(
        private contact: ContactSendService,
        private color: ColorScssService
        ) {
        this.color.resetColorScheme()
    }

    ngOnInit() {

    }

    onSubmit() {
        if (this.contactForm.invalid) {
            console.log('Form is invalid');
            return;
        }

        const payload = {
            first_name: this.contactForm.get('firstName')?.value,
            last_name: this.contactForm.get('lastName')?.value,
            email: this.contactForm.get('email')?.value,
            company_name: this.contactForm.get('companyName')?.value,
            text: this.contactForm.get('message')?.value,
        };

        this.contact.send_new_contact(payload).subscribe({
            next: response => {
                console.log(response);
                this.contactForm.reset();
            },
            error: error => {
                console.error('Error:', error);
            }
        });
    }
}
