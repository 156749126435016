import {Injectable} from '@angular/core';
import {environment} from '../../environments/environments';
import {HttpClient} from '@angular/common/http';
import {map, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ScrapingRequest} from '../../CustomComponents/Objects/SearchResult';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class CheckoutService {

    private apiUrlAddOrder = `${environment.apiUrl}/buy-table`;
    private apiUrlRequestTable = `${environment.apiUrl}/request-table`;
    private apiUrlGetTablePrice = `${environment.apiUrl}/get_table_price`;
    private scrapingRequest: ScrapingRequest = null;

    constructor(private http: HttpClient,
                private auth: AuthService,
    ) {
    }

    send_new_scraping_request() {
        const request_payload = {
            user_id: this.auth.getUserId(),
            industry: this.scrapingRequest.industry,
            region: this.scrapingRequest.region,
            row_count: this.scrapingRequest.row_count,
            price: this.scrapingRequest.price,
            type: this.scrapingRequest.type,
        };

        console.log(request_payload);

        this.http.post<{ message: string }>(this.apiUrlRequestTable, request_payload).subscribe(
            (response) => {
                console.log('Response message:', response.message);
            },
            (error) => {
                console.error('Error retrieving search results:', error);
            }
        );
    }

    send_existing_table_order(table_name: string, checkout_form) {
        const user_ident = {
            user_id: this.auth.getUserId(),
            table_name: table_name,
        };

        this.http.post<{ message: string }>(this.apiUrlAddOrder, user_ident).subscribe(
            (response) => {
                console.log('Response message:', response.message);
            },
            (error) => {
                console.error('Error retrieving search results:', error);
            }
        );
    };

    get_price_for_table(table_name: string): Observable<number> {
        const table_info = {
            table_name: table_name
        };

        return this.http.post<{ price: number }>(this.apiUrlGetTablePrice, table_info).pipe(
            map(response => response.price), // Extract the price from the response
            catchError((error) => {
                console.error('Error retrieving search results:', error);
                return throwError(error); // Propagate the error
            })
        );
    }

    set_scraping_request(scraping_request: ScrapingRequest) {
        this.scrapingRequest = scraping_request;
    }

    get_scraping_request():ScrapingRequest {
        return this.scrapingRequest;
    }
}
