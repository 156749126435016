<div class="thank-you-container">
    <div class="thank-you-card">
        <h1>Vielen Dank für Ihre Bestellung!</h1>
        <p class="intro-text">
            Ihre Bestellung war erfolgreich und wir freuen uns, Ihnen mitteilen zu können, dass die Rechnung an
            <strong>{{ email }}</strong> gesendet wurde.
        </p>
        <p class="check-email">
            Bitte überprüfen Sie Ihren Posteingang, um die Rechnung einzusehen.
        </p>
        <p class="gratitude">
            Wir schätzen Ihr Vertrauen in uns und bedanken uns herzlich für Ihren Einkauf.
        </p>
        <p class="order-status">
            Den aktuellen Status Ihrer Bestellung können Sie jederzeit in Ihrem
            <a href="#" (click)="goToProfile(); $event.preventDefault()">Profil</a> einsehen.
        </p>
        <p class="support">
            Falls Sie Fragen haben oder Unterstützung benötigen, zögern Sie nicht, sich jederzeit an unseren
            <a href="/kontakt">Kundensupport</a> zu wenden.
        </p>
    </div>
</div>
