<!-- build section start -->
<section class="saas1 build-bg" [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="build-right-slider">
        <!--
        <img src="assets/images/saas1/work.png" alt="work-slider" class="img-fluid work-slide">
        -->
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">

                <div class="build-box">
                    <h3 class="build-head text-white"><span class="theme-color">Optimieren  </span>Sie Ihre Lead-Gewinnung</h3>
                    <p>{{ 'Profitieren Sie von maßgeschneiderten Datenlösungen, die Ihnen helfen, Ihre Zielgruppe effektiv zu erreichen und Ihre Marketingstrategien zu verbessern. Unsere Plattform bietet Ihnen alles, was Sie für eine erfolgreiche Lead-Generierung benötigen.' }}</p>
                    <ul>
                        <li>
                            <img src="../../../../assets/images/saas1/build-.png" alt="icon">
                            Wir bieten eine kostenlose Erstberatung und Unterstützung.
                        </li>
                        <li><img src="../../../../assets/images/saas1/build-.png" alt="icon">
                            Zugang zu E-Mail-Adressen für gezielte Marketingkampagnen
                        </li>
                        <li>
                            <img src="../../../../assets/images/saas1/build-.png" alt="icon">
                            Benutzerfreundliche Plattform zur einfachen Verwaltung Ihrer Daten.
                        </li>
                        <li>
                            <img src="../../../../assets/images/saas1/build-.png" alt="icon">
                            Schnelle Bereitstellung der Daten für zeitnahe Entscheidungen..
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end build section-->
