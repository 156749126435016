import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {map, Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';
import {MatFormField} from '@angular/material/form-field';

@Component({
  selector: 'app-search-menu-regions',
  templateUrl: './search-menu-regions.component.html',
  styleUrl: './search-menu-regions.component.scss'
})
export class SearchMenuRegionsComponent implements OnInit {

  myControl = new FormControl('');
  options: string[] = [
    'Deutschlandweit',
    'Baden-Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein-Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen'
  ];
  filteredOptions: Observable<string[]> | undefined;
  currentValue: string = '';

  // Define an output property to emit value changes
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    // Capture initial value
    this.currentValue = this.myControl.value ?? '';

    // Set up filteredOptions observable
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
    );

    // Subscribe to valueChanges to emit and update currentValue as it changes
    this.myControl.valueChanges.subscribe(value => {
      const selectedValue = value ?? '';

      if (this.options.includes(selectedValue)) {
        this.currentValue = selectedValue;
      } else {
        this.currentValue = '';
      }
      this.valueChanged.emit(this.currentValue); // Emit the selected value or empty string
    });
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}


