<!--service section start-->
<section class="agency format service-bg" id="features" [ngStyle]="{'background-image': 'url(assets/images/Own/services-icons/services-backgrondpng.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-4 offset-lg-1">
                <div class="center-text">
                    <div class="format-container">
                        <h6 class="borders-before text-uppercase">
                            <span class="">Our services</span>
                        </h6>
                        <div class="format-head-text">
                            <h2 class="about-font-header">Unsere
                                <span class="block-span">leistungsstarken Services</span></h2>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para">Wir bieten maßgeschneiderte Lösungen, die auf Ihre Bedürfnisse zugeschnitten sind und Ihnen helfen, Ihre Ziele effizient zu erreichen.</p>
                        </div>
                        <a href="#" [routerLink]="'/kontakt'" class=" btn btn-default btn-gradient text-white">Mehr Anzeigen</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-8">
                <div class="row">
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection" [@fadeInRight]="{ value: fadeInRight, params: { timing: 3, delay: 0 } }">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/Own/services-icons/1.png"
                                                                        alt="">
                                    <h5>Lead-Generierung</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection" [@fadeInRight]="{ value: fadeInRight, params: { timing: 3, delay: 2 } }">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/Own/services-icons/2.png"
                                                                        alt="">
                                    <h5>Zielgerichtete Datensätze</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/Own/services-icons/3.png"
                                                                        alt="">
                                    <h5>Cold Email Marketing</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 center-content unset-height">
                        <ul class="icon-collection" [@fadeInRight]="{ value: fadeInRight, params: { timing: 3, delay: 3 } }">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/Own/services-icons/4.png"
                                                                        alt="">
                                    <h5>Lead-Akquise</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/Own/services-icons/5.png"
                                                                        alt="">
                                    <h5>B2B-Lead-Service</h5>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/Own/services-icons/6.png"
                                                                        alt="">
                                    <h5>Schnell & einfach</h5>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--service section end-->
