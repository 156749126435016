import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../Services/BuissnessLogic/auth.service';
import {ColorScssService} from '../../shared/service/color-scss.service';
import {InteliRouteService} from '../../Services/inteli-route.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  password_error:boolean = false;
  loginForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private color: ColorScssService,
              private intRoute: InteliRouteService,
  ) {
    color.resetColorScheme()
    // Initialize the form group
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {

  }

  // todo add auth Service Connection here
  onSubmit(): void {
    if (!this.loginForm.valid) {
      console.log('Form is invalid');
      this.password_error = true;
      return;
    }
    this.password_error = false;
    const username = this.loginForm.get('email')?.value;
    const password = this.loginForm.get('password')?.value;

    this.authService.login(username, password).subscribe({
      next: response => {
        if (this.authService.isLoggedIn()) {
          if (this.intRoute.is_valid()) {
            this.intRoute.navigateToStoredRoute();
            return;
          }

          this.router.navigate(['/profil'])
          this.password_error = false;
        }
      },
      error: error => {
        console.error('Error:', error);
        this.password_error = true;
      }
    });
  }
}
