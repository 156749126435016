import {Component, OnInit} from '@angular/core';


interface FaqItem {
    question: string;
    answer: string;
}

@Component({
    selector: 'app-enterprice-sass-faq',
    templateUrl: './enterprice-sass-faq.component.html',
    styleUrls: ['./enterprice-sass-faq.component.scss']
})
export class EnterpriceSassFaqComponent implements OnInit {

    public items: FaqItem[] = [
        {
            question: 'Ist der Kauf sicher und wie erfolgt die Bezahlung?',
            answer: 'Ja, der Kaufprozess ist sicher und verschlüsselt. Die Bezahlung erfolgt per Banküberweisung, und nach Zahlungseingang erhalten Sie innerhalb eines Tages Zugriff auf Ihre gekauften Daten.'
        },
        {   question: 'Wie werden die Daten gesammelt?',
            answer: 'Wir verwenden eine Kombination aus öffentlich zugänglichen Quellen und speziellen Datenbanken, die eine hohe Genauigkeit und Aktualität gewährleisten.'},
        {
            question: 'In welchen Formaten kann ich die Daten exportieren?',
            answer: 'Sie können die Daten in verschiedenen Formaten wie CSV oder Excel exportieren und so flexibel in Ihre eigenen Systeme integrieren.'
        },
    ];

    constructor() {
    }

    ngOnInit() {
    }

}
