import {Component, OnInit} from '@angular/core';
import {ColorScssService} from '../../shared/service/color-scss.service';
import {SeoService} from '../../Services/SEO/seo.service';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrl: './impressum.component.scss'
})
export class ImpressumComponent implements OnInit {
  constructor(private color: ColorScssService,
              private seoService: SeoService ) {

    this.seoService.setMetaData("impressumsPage");
    this.color.resetColorScheme();
  }

  ngOnInit() {

  }
}
