import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../Services/BuissnessLogic/auth.service';
import {HttpClient} from '@angular/common/http';
import {CheckoutService} from '../../../Services/BuissnessLogic/checkout.service';
import {ColorScssService} from '../../../shared/service/color-scss.service';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
    public table_name = '';
    public checkoutForm: FormGroup;
    public total_price: number = 0;
    public isEmpty = false;
    public checkout_items: { name: string, price: number }[] = [];
    public submitted:boolean = false;

    // Form Validator
    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private auth: AuthService,
                private checkout: CheckoutService,
                private color: ColorScssService
    ) {
        if (!this.auth.isLoggedIn()) {
            this.router.navigate(['/home']);
            return;
        }
        color.resetColorScheme()
        this.checkoutForm = this.fb.group({
            firstname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            lastname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
            phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
            email: ['', [Validators.required, Validators.email]],
            address: ['', [Validators.required, Validators.maxLength(50)]],
            country: ['', Validators.required],
            town: ['', Validators.required],
            postalcode: ['']
        });
    }

    ngOnInit() {
        this.table_name = this.route.snapshot.paramMap.get('tableName');
        if (this.table_name === "new_table") {
            const checkoutElement = this.checkout.get_scraping_request()
            this.checkout_items.push({name: checkoutElement.industry + " " + checkoutElement.region, price: checkoutElement.price});
            this.calculate_total();

        } else {
            this.get_price_from_backend(this.table_name);
        }
    }

    get_price_from_backend(table_name: string): void {
        this.checkout.get_price_for_table(table_name).subscribe(
            (price) => {
                this.checkout_items.push({name: this.table_name, price: price});
                this.calculate_total();
            },
            (error) => {
                console.error('Error retrieving price:', error);
            }
        );
    }

    public calculate_total(): void {
        let amount: number = 0;
        for (let item of this.checkout_items) {
            amount += item.price;
        }

        this.total_price = Number(amount);
    }

    onSubmit() {
        if (!this.auth.isLoggedIn()){
            this.router.navigate(["/register"]);
            return;
        }

        if (this.checkoutForm.invalid) {
            console.log("Invalid Form!");
            return;
        }

        // todo send the checkout form info

        if (this.checkout.get_scraping_request() != null) {
            this.checkout.send_new_scraping_request();
        } else {
            this.checkout.send_existing_table_order(this.table_name, this.checkoutForm);
        }
        this.router.navigate(["/thank-you"]);
    }

}
