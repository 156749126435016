import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnterpriceSassComponent} from './LandingPage/enterprice-sass/enterprice-sass.component';
import { ItemView} from './ProductSearch/ScrollExisting/ItemView/item-view';
import { ScrollView} from './ProductSearch/ScrollExisting/ScrollView/scroll-view';
import { CheckoutComponent} from './ProductSearch/Finalisation/checkout/checkout.component';
import { ProfileComponent} from './UserPages/Profile/profile.component';
import { LoginComponent} from './UserPages/login/login.component';
import { SignUpComponent} from './UserPages/sign-up/sign-up.component';
import {ThankyouForOrderComponent} from './ProductSearch/Finalisation/thankyou-for-order/thankyou-for-order.component';
import {PageNotFoundComponent} from './StaticSites/page-not-found/page-not-found.component';
import {ImpressumComponent} from './StaticSites/impressum/impressum.component';
import {ContactComponent} from './contact/contact.component';
import {GenerationPageComponent} from './ProductSearch/GenerationPage/generation-page/generation-page.component';
import {AboutPage} from './AboutPage/about-page.component';
import {LeadGeneationPageComponent} from './LeadGeneration/lead-geneation-page/lead-geneation-page.component';

export const routes: Routes = [
  {
    path: '',
    component: EnterpriceSassComponent,
    pathMatch: 'full',
  },
  {
    path: 'anmelden',
    component: LoginComponent
  },
  {
    path: 'registrieren',
    component: SignUpComponent
  },
  {
    path: 'profil',
    component: ProfileComponent
  },
  {
    path: 'checkout/:tableName',
    component: CheckoutComponent
  },
  {
    path: 'tabellen-ansicht/:tableName',
    component: ItemView
  },
  {
    path: 'thank-you',
    component: ThankyouForOrderComponent
  },
  {
    path: 'vorhandene-tabellen',
    component: ScrollView
  },
  {
    path: 'tabelle-erstellen',
    component: LeadGeneationPageComponent
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  {
    path: 'kontakt',
    component: ContactComponent,
  },
  {
    path: 'services',
    component: AboutPage,
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
