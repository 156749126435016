<!--footer start-->
<footer class="saas1 footer2">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">About Us</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="../../../../assets/images/Own/Palladio_h_40.png" alt="" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">Obrecht Studios</h6>
                            <h6 class="text-white para-address">Karlsruhe 76137.</h6>
                        </div>
                        <ul class="d-d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">services</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a [routerLink]="['/vorhandene-tabellen']">Tabelle Suchen</a></li>
                            <li class=""><a [routerLink]="['/tabelle-erstellen']">Tabelle Erstellen</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Profil</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Profil</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a [routerLink]="['/login']">Anmelden</a></li>
                            <li class=""><a [routerLink]="['/registrieren']">Registrieren</a></li>
                            <li class=""><a [routerLink]="['/profil']">Profil</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Support</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Support</h5>
                    <div>
                        <ul class="footer-lists">
                            <li class=""><a [routerLink]="['/services']">About</a></li>
                            <li class=""><a [routerLink]="['/impressum']">Impressum</a></li>
                            <li class=""><a [routerLink]="['/kontakt']">Kontakt</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>
