import { Component } from '@angular/core';
import {AppModule} from '../app.module';
import {ColorScssService} from '../shared/service/color-scss.service';

@Component({
  selector: 'app-faqpage',
  templateUrl: './about-page.component.html',
  styleUrl: './about-page.component.scss'
})
export class AboutPage {
  constructor(private color: ColorScssService) {
    this.color.resetColorScheme();
  }



}
