<!-- faq section -->
<section id="faq" class="saas1 faq testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="faq-block">
                    <div>
                        <h3 class="frequent-text">Häufig gestellte Fragen (FAQ)</h3>
                        <h6>Erhalten Sie Antworten auf die wichtigsten Fragen rund um unsere Datenservices und wie diese Ihnen helfen können, Ihre B2B-Lead-Generierung und Geschäftsentwicklung optimal zu unterstützen.
                        </h6>
                        <div class="card">
                            <div class="accordion faq" id="accordionExample">
                                <div ngbAccordion [closeOthers]="true">
                                    <ng-container *ngFor="let item of selectedItems;">
                                        <div ngbAccordionItem [collapsed]="item.question !== 'Do I need to make a payment?'">
                                            <h2 ngbAccordionHeader>
                                                <button ngbAccordionButton>
                                                    <a>
                                                        <div class="fa fa-angle-right rotate"></div>
                                                    </a>
                                                    {{ item.question }}
                                                </button>
                                            </h2>
                                            <div ngbAccordionCollapse>
                                                <div ngbAccordionBody>
                                                    <ng-template>
                                                        {{ item.answer }}
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <h6 class="link">Haben Sie noch eine Frage? Kontaktieren Sie uns: <a>kontakt&#64;palladio-leads.de</a></h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="faq-img-block">
                    <img src="assets/images/Own/faq-img.png" alt="faq-person" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
