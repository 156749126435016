<!--section 404 start-->
<div class="bg-purple">
    <div class="section-404">
        <div class="stars" [ngStyle]="{'background-image': 'url(assets/images/404/overlay_stars.svg)'}">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2 col-12">
                        <div class="central-body">
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>

                            <h1 class="text-inner">404</h1>
                            <h3 class="sub-text text-white">Seite nicht gefunden</h3>
                            <p class="text-404 text-white text-center">Die Seite, die Sie erreichen möchten, ist derzeit
                                nicht verfügbar. Dies kann daran liegen, dass die Seite nicht existiert oder verschoben
                                wurde.</p>
                            <a [routerLink]="['/home']" class="btn btn-default primary-btn transparent m-t-20">Zurück
                                zur Startseite</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="objects">
            <img class="object_rocket" src="assets/images/404/rocket.svg">
            <div class="earth-moon">
                <img class="object_earth" src="assets/images/404/earth.svg">
                <img class="object_moon" src="assets/images/404/moon.svg">
            </div>
            <div class="box_astronaut">
                <img class="object_astronaut" src="assets/images/404/astronaut.svg">
            </div>
        </div>
        <div class="glowing_stars">
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
            <div class="star"></div>
        </div>
    </div>
</div>
<!--section 404 end-->
