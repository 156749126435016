<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain ">
                    <div>
                        <h2 class="breadcrumb-txt">Vorhandene Tabellen</h2>
                    </div>
                    <div>
                        <ul>
                            <li><a href="#">Suchen</a></li>
                            <li><a href="#"><i class="fa fa-angle-double-right"></i>Tabellen Durchsuchen</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--breadcrumb section end -->
<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <!-- Collection Sidebar -->
                <a class="overlay-sidebar" ></a>
                <div class="col-sm-3 collection-filter">
                    <div class="coll_sidebar_sticky">
                        <div class="collection-filter-block">
                            <div *ngIf="supported_regions.length">
                                <div *ngFor="let region of supported_regions" class="region-filter">
                                    <input
                                            type="checkbox"
                                            [value]="region"
                                            (change)="onRegionCheckboxChange($event, region)"
                                            class="custom-checkbox"
                                            id="checkbox-{{region}}"
                                    />
                                    <label for="checkbox-{{region}}" class="region-label">{{ region }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Collection Sidebar -->
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="top-banner-wrapper">
                                        <a><img src="../../../../assets/images/Own/scroll_view_banner.png" class="img-fluid"
                                                alt=""></a>
                                        <div class="top-banner-content small-section">
                                            <h4>Tabellen</h4>
                                            <h5>Durchsuche Vorhandenen Tabellen</h5>
                                            <p>Durchsuchen Sie alle verfügbaren Tabellen, um die passenden Lead-Listen für Ihr Geschäft direkt zu erwerben. Unser Service ermöglicht Ihnen den schnellen Zugang zu hochwertigen Kontakten, die Ihr Unternehmen voranbringen. Finden Sie die für Sie relevanten Leads und starten Sie sofort mit der Umsetzung Ihrer Kampagnen.</p>
                                        </div>
                                    </div>
                                    <div class="branchefilterframe_2-460">
                                        <span class="text-branche-suchen_2-465">Branche Suchen</span>
                                        <app-search-dropdown-menu
                                                (valueChanged)="onDropdownValueChangedIndustry($event)">
                                            <!-- [options]="Array.from(industries)"> -->
                                        </app-search-dropdown-menu>
                                    </div>
                                    <div class="collection-product-wrapper">
                                        <div class="product-wrapper-grid">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="list-item shadow-lg p-3 mb-4"
                                                         *ngFor="let item of displayedSearchResults">
                                                        <div class="list-item-header">
                                                            <h3 class="table-name">{{ item.industry }}</h3>
                                                            <div class="item-date">{{ item.age }}</div>
                                                        </div>
                                                        <div class="list-item-body">
                                                            <div class="item-info">
                                                                <span class="label">Branche:</span>
                                                                <span class="value">{{ item.industry }}</span>
                                                            </div>
                                                            <div class="item-info">
                                                                <span class="label">Bundesland:</span>
                                                                <span class="value">{{ item.region }}</span>
                                                            </div>
                                                            <div class="item-info">
                                                                <span class="label">Zeilen Anzahl:</span>
                                                                <span class="value">{{ item.row_count }}</span>
                                                            </div>
                                                            <div class="item-info">
                                                                <span class="label">Preis:</span>
                                                                <span class="value">{{ item.price + ' €' }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="list-item-actions">
                                                            <a class="view-text"
                                                               [routerLink]="['/table-view', item.table_name]"
                                                               target="_self">
                                                                View &gt;
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 text-center section-b-space mt-5 no-found"
                                                         *ngIf="!isEmpty">
                                                        <img src="../../../../assets/images/empty-search.jpg"
                                                             class="img-fluid mb-4">
                                                        <h3>Sorry! Couldn't find the product you were looking
                                                            For!!! </h3>
                                                        <p>Please check if you have misspelt something or try searching
                                                            with other words.</p>
                                                        <a [routerLink]="'/e-commerce'"
                                                           class="btn btn-default primary-btn ra-dius-0 m-t-10">continue
                                                            shopping</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
