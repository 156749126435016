import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-feature',
  templateUrl: './enterprice-sass-feature.component.html',
  styleUrls: ['./enterprice-sass-feature.component.scss']
})
export class EnterpriceSassFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  features= [
    {
      title:'Gezielte ',
      subTitle: 'Lead-Listen',
      description: 'Zugriff auf Lead-Listen, die speziell auf Ihre Zielgruppe zugeschnitten sind – sparen Sie Zeit und maximieren Sie die Relevanz Ihrer Kundenansprache.',
      img: 'assets/images/saas1/feature/1.png',
    },
    {
      title:'Schnelle',
      subTitle: 'Datenermittlung',
      description: 'Profitieren Sie von einer stabilen und schnellen Datenbereitstellung, um zeitnah auf Marktanforderungen zu reagieren.',
      img: 'assets/images/saas1/feature/2.png',
    },
    {
      title:'Verifizierte',
      subTitle: 'E-Mail-Adressen',
      description: 'Profitieren Sie von verifizierten E-Mail-Adressen, um direkt mit potenziellen Kunden in Kontakt zu treten und Ihre Reichweite effizient zu erhöhen.',
      img: 'assets/images/saas1/feature/3.png',
    },
    {
      title:'Standortbasierte',
      subTitle: 'Filter',
      description: 'Suchen Sie gezielt nach Unternehmen an spezifischen Standorten und entdecken Sie neue Marktpotenziale in relevanten Regionen.',
      img: 'assets/images/saas1/feature/4.png',
    },
    {
      title:'Einfacher',
      subTitle: 'Download',
      description: 'Nutzen Sie eine benutzerfreundliche Plattform, die Ihnen den unkomplizierten Download der benötigten Daten ermöglicht – jederzeit und sofort einsatzbereit.',
      img: 'assets/images/saas1/feature/5.png',
    },
    {
      title:'Regelmäßige',
      subTitle: 'Datenaktualisierungen',
      description: 'Unsere Datenbank wird ständig aktualisiert, um sicherzustellen, dass Sie Zugang zu den aktuellsten Unternehmensinformationen haben.',
      img: 'assets/images/saas1/feature/6.png',
    }
  ]
}
