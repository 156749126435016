<!-- feature section-->
<section id="feature" class="saas1 service">
    <div class="about-chat">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="title">
                        <img src="../../../../assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid">
                        <div class="main-title">
                            <h2>
                                <span class="theme-color">K</span>undenakquise <span class="theme-color">L</span>eicht  <span class="theme-color">g</span>emacht

                            </h2>
                        </div>
                        <hr>
                        <div class="sub-title">
                            <p class="p-padding">Optimieren Sie Ihre B2B-Strategie mit geprüften Daten und relevanten Geschäftskontakten</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 service-container" *ngFor="let data of features">
                    <div class="chat-box">
                        <img [src]="data.img" alt="stay-connected">
                        <div class="feature-content">
                            <h4 class="service-heading"> {{data.title}} <br>
                                <span class="theme-color"> {{data.subTitle}} </span>
                            </h4>
                            <hr>
                            <p>{{data.description}}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- end feature section -->
