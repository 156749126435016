import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {SEO_DATA} from './seo-data';
import {SearchResult} from '../../CustomComponents/Objects/SearchResult';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private meta: Meta,
              private titleService: Title
  ) {
  }

  setMetaData(page: string) {
    const seoTags = SEO_DATA[page];
    if (seoTags) {
      this.titleService.setTitle(seoTags.title);
      this.meta.updateTag({ name: 'title', content: seoTags.title });
      this.meta.updateTag({ name: 'description', content: seoTags.description });
      this.meta.updateTag({ name: 'keywords', content: seoTags.keywords });
      this.meta.updateTag({ property: 'og:title', content: seoTags.ogTitle });
      this.meta.updateTag({ property: 'og:description', content: seoTags.ogDescription });
    }
  }

  itemViewPage = {
    title: 'Lead-Details - Palladio',
    description: 'Erhalten Sie detaillierte Informationen zu unseren angebotenen Leads.',
    keywords: 'Lead-Details, B2B-Leads Deutschland, Geschäftsinfos',
    ogTitle: 'Lead-Details - Palladio',
    ogDescription: 'Erfahren Sie mehr über unsere spezifischen Leads und wie Palladio Ihr Wachstum fördern kann.'
  };

  setMetaDataItemView(searchResult: SearchResult) {
    if (searchResult) {
      const seoTags = {
        title: `${this.itemViewPage.title} - ${searchResult.display_table_name} (${searchResult.industry})`,
        description: `${this.itemViewPage.description} Diese Tabelle enthält ${searchResult.row_count} Einträge aus der ${searchResult.industry}-Branche in ${searchResult.region}.`,
        keywords: `${this.itemViewPage.keywords}, ${searchResult.industry}, ${searchResult.region}, ${searchResult.age}, ${searchResult.table_name}`,
        ogTitle: `${this.itemViewPage.ogTitle} - ${searchResult.display_table_name}`,
        ogDescription: `${this.itemViewPage.ogDescription} Mit ${searchResult.row_count} Leads in der ${searchResult.industry}-Branche aus ${searchResult.region}.`
      };

      this.titleService.setTitle(seoTags.title);
      this.meta.updateTag({ name: 'title', content: seoTags.title });
      this.meta.updateTag({ name: 'description', content: seoTags.description });
      this.meta.updateTag({ name: 'keywords', content: seoTags.keywords });
      this.meta.updateTag({ property: 'og:title', content: seoTags.ogTitle });
      this.meta.updateTag({ property: 'og:description', content: seoTags.ogDescription });
    }
  }
}
