<!-- impressum.component.html -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain ">
                    <div>
                        <h2 class="breadcrumb-txt">Obrecht Industries</h2>
                    </div>
                    <div>
                        <ul>
                            <li><a href="#">Infos</a></li>
                            <li><a href="#"><i class="fa fa-angle-double-right"></i> Impressum </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="impressum-container">
    <h1>Impressum</h1>
    <div class="content">
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
            Martin Obrecht<br />
            Schützenstraße<br />
            76135 Karlsruhe
        </p>
        <h2>Vertreten durch:</h2>
        <p>Geschäftsführer: Martin Obrecht</p>

        <h2>Kontakt:</h2>
        <p>
            E-Mail: <a href="mailto:kontakt@palladio-leads.de">kontakt(at)palladio-leads.de</a>
        </p>
    </div>
</section>
