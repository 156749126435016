<!-- work section -->
<section id="how-work" class="saas1 howitwork"  [ngStyle]="{'background-image': 'url(assets/images/saas1/work-bg.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="title">
                    <img src="../../../../assets/images/saas1/title-logo.png" alt="title-logo" class="img-fluid">
                    <div class="main-title">
                        <h2>
                            Tabelle Generieren
                        </h2>
                    </div>
                    <hr>
                    <div class="sub-title">
                        <p class="p-padding">In unserem interaktiven Step-by-Step-Guide erstellen und konfigurieren Sie Ihre persönliche Lead-Tabelle. Durchlaufen Sie jeden Schritt und passen Sie die Tabelle exakt an Ihre Bedürfnisse an – schnell, intuitiv und effizient.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 work-tab">
                <ul class="nav nav-pills justify-content-center " id="pills-tab" role="tablist">
                    <li class="nav-item  text-center" *ngFor="let data of nav">
                        <a class="nav-link" id="pills-work1-tab" [ngClass]="{'active show': activeTab == data.title }"
                           (click)="openWorkSlide(data.title)">
                            <img [src]="data.img" alt="tab-image-1">
                            <h6>{{data.title}}</h6>
                            <span></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="work-tab-bg work-content p-t-50">
        <ng-container *ngFor="let data of content">
            <ng-container *ngIf="activeTab === data.title">
                <ng-template [ngTemplateOutlet]="data.template"></ng-template>
            </ng-container>
        </ng-container>
    </div>
</section>

<ng-template #template1>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6">
                <div class="p-3 text-center">
                    <h3>Branche auswählen</h3>
                    <p>Bestimmen Sie, aus welcher Branche Ihre Leads stammen sollen, und legen Sie den Fokus auf Ihre Zielgruppe.</p>
                </div>
                <div class="col-12 mb-3">
                    <label>Branche</label>
                    <input
                            class="form-control"
                            [(ngModel)]="selectedIndustry"
                            placeholder="Gib deine Branche ein"
                    >
                    <p *ngIf="showTemplateError1" class="error-text">Branche hier eingeben</p>
                    <label>Notiz</label>
                    <textarea
                            class="form-control"
                            placeholder="Schreibe weitere Anforderungen"
                            id="exampleFormControlTextarea1"
                            rows="5"
                            [(ngModel)]="industryUserNote"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #template2>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6">
                <div class="p-3 text-center">
                    <h3>Region auswählen</h3>
                    <p>Bestimmen Sie, aus welcher Region Ihre Leads kommen sollen, um Ihre Zielgruppe gezielt anzusprechen.</p>
                </div>
            </div>
        </div>
        <div class="collection-filter-block">
            <div *ngFor="let region of supported_regions" class="region-filter">
                <input
                        type="checkbox"
                        [checked]="selected_regions.includes(region)"
                        (change)="onRegionCheckboxChange($event, region)"
                        class="custom-checkbox"
                        id="checkbox-{{region}}"
                />
                <label for="checkbox-{{region}}" class="region-label">{{ region }}</label>
            </div>
        </div>
        <p *ngIf="showTemplateError2" class="error-text">Mindestens eine Region auswählen</p>
    </div>
</ng-template>


<ng-template #template3>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6">
                <div class="p-3 text-center">
                    <h3>Spalten auswählen</h3>
                    <p>Wählen Sie die gewünschten Spalten aus, die in Ihrer Lead-Tabelle angezeigt werden sollen, um die relevanten Informationen anzuzeigen.</p>
                </div>
            </div>
        </div>
    </div>
    <section class="resume pricing">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <owl-carousel-o [options]="pricingcarouselOptions2" class="pricing-slider">
                        <ng-container *ngFor="let price of pricing2; let i = index">
                            <ng-template carouselSlide class="item">
                                <div class="price-container price-margin shadows text-center">
                                    <div
                                            class="price-feature-container set-relative"
                                            [class.selected]="selectedPaymentIndex === i"
                                            (click)="onFeatureClick(i)"
                                    >
                                        <div class="feature-text">
                                            <span class="flaticon-{{price.icon}} feature-icon"></span>
                                            <h4 class="feature-text-heading bold text-uppercase">{{price.package}}</h4>
                                            <hr class="set-border">
                                        </div>
                                        <div class="price-features">
                                            <h5 class="price-feature text-center">{{price.feature1}}</h5>
                                            <h5 class="price-feature text-center">{{price.feature2}}</h5>
                                            <h5 class="price-feature text-center">{{price.feature3}}</h5>
                                            <h5 class="price-feature text-center">{{price.feature4}}</h5>
                                            <h5 class="price-feature text-center">{{price.feature5}}</h5>
                                            <h5 class="price-feature text-center">{{price.feature6}}</h5>
                                            <h5 class="price-feature text-center">{{price.feature7}}</h5>
                                        </div>
                                        <div class="price-value">
                                            <h6 class="price text-center"><span class="large">{{price.price}}</span>€/Lead</h6>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </section>
</ng-template>

<ng-template #template4>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6">
                <div class="p-3 text-center">
                    <h3>Zeilenanzahl auswählen</h3>
                    <p>Bestimmen Sie die Anzahl der Zeilen für Ihre Tabelle, um die gewünschte Datenmenge anzuzeigen.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="quantity-selector">
                <h4>Zeilenanzahl</h4>
                <span class="mengen-info">Stelle hier die Zeilenanzahl ein:</span>
                <div class="quantity-selector-element center-content">
                    <input
                            type="range"
                            min="500"
                            max="10000"
                            step="100"
                            [value]="selectedRowCount"
                            (input)="onSliderChange($event)"
                            class="custom-slider"
                    />
                    <div class="slider-labels">
                        <span>500</span>
                        <span>10,000</span>
                    </div>
                    <p class="selected-value">Gewählte Zeilenanzahl: {{ selectedRowCount }}</p>
                </div>
                <div class="info-line">
                    <svg class="info-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="12" cy="12" r="10"/>
                        <line x1="12" y1="16" x2="12" y2="12"/>
                        <line x1="12" y1="8" x2="12" y2="8"/>
                    </svg>
                    <span class="info-text">Falls weniger Firmen existieren als ausgewählt <br> wird die differenz erstattet</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #template5>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6">
                <div class="p-3 text-center">
                    <h3>Jetzt bestellen</h3>
                    <p>Schließen Sie den Vorgang ab und bestellen Sie Ihre individuell erstellte Lead-Tabelle.</p>
                </div>
                <div class="p-3">
                    <div class="d-flex justify-content-between">
                        <span><strong>Zeilenanzahl:</strong></span>
                        <span>{{"1000"}}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <span><strong>Preis:</strong></span>
                        <span>{{"3" | currency:'EUR'}}</span>
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <a (click)="goToCheckOut(0)" class="btn btn-default btn-gradient text-white">Tabelle Bestellen</a>
                </div>
            </div>
        </div>
    </div>
</ng-template>



