import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from '../LandingPage/NavigationMenu/menu/menu.component';
import { TapToTopComponent } from '../LandingPage/enterprice-sass/tap-to-top/tap-to-top.component';
import { HeaderComponent } from './components/pages/header/header.component';
import { BreadcrumbComponent } from './components/pages/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/pages/footer/footer.component';
import { RouterModule } from '@angular/router';
import { CenterMenuComponent } from './components/navigation/center-menu/center-menu.component';
import { ToastrModule } from 'ngx-toastr';
import { OrderByPipe } from './pipes/order-by.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import {
  EnterpriceSassTestimonialComponent
} from '../LandingPage/enterprice-sass/enterprice-sass-testimonial/enterprice-sass-testimonial.component';
import {CarouselModule} from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [TapToTopComponent, HeaderComponent, BreadcrumbComponent, FooterComponent, CenterMenuComponent, OrderByPipe, LoaderComponent, EnterpriceSassTestimonialComponent],
  exports: [
    LoaderComponent,
    CenterMenuComponent,
    TapToTopComponent,
    HeaderComponent,
    BreadcrumbComponent,
    FooterComponent,
    OrderByPipe,
    EnterpriceSassTestimonialComponent
  ],
  imports: [
    CarouselModule,
    CommonModule,
    RouterModule,
    ToastrModule.forRoot()
  ]
})
export class SharedModule { }
