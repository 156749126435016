<form class="example-form">
    <mat-form-field class="example-full-width">
        <mat-label>Branche Suchen . . .</mat-label>
        <input type="text"
               placeholder="Branche Suchen"
               aria-label="Branche Suchen"
               matInput
               [formControl]="myControl"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
