import { Component, OnInit } from '@angular/core';
import {ColorScssService} from '../../shared/service/color-scss.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private color: ColorScssService) {
      this.color.setColorScheme("color-6")
  }

  ngOnInit() {
  }

}
