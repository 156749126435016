import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../Services/BuissnessLogic/auth.service';
import {ColorScssService} from '../../../shared/service/color-scss.service';

@Component({
    selector: 'app-enterprice-sass-nav',
    templateUrl: './enterprice-sass-nav.component.html',
    styleUrls: ['./enterprice-sass-nav.component.scss']
})
export class EnterpriceSassNavComponent implements OnInit {
    isLoggedIn: boolean = false;
    private userIdSubscription: Subscription | null = null;

    constructor(private auth: AuthService,
    ) {
    }

    ngOnInit(): void {
        this.userIdSubscription = this.auth.userId$.subscribe(userId => {
            this.isLoggedIn = !!userId;
            console.log('Is Logged In' + this.isLoggedIn);
        });
    }

}
