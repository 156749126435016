<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain ">
                    <div>
                        <h2 class="breadcrumb-txt">Produkt Ansicht</h2>
                    </div>
                    <div>
                        <ul>
                            <li><a href="#">Leads</a></li>
                            <li><a href="#"><i class="fa fa-angle-double-right"></i>{{ searchResult.displayTableName }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="agency">
    <section class="item-section">
        <div class="collection-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xs-12">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-10">
                                    <div class="product-right pro_sticky_info">
                                        <h2>{{ searchResult.industry }}</h2>
                                        <h3>
                                            <span>{{searchResult.price}} €</span>
                                        </h3>
                                        <h4>{{ searchResult.rowCount + " Zeilen" }}</h4>
                                        <h4>{{ "Bundsland: " + searchResult.region}}</h4>
                                        <div class="product-description border-product">
                                        </div>
                                        <div class="product-buttons">
                                            <a (click)="proceed_to_checkout()"
                                               class="btn btn-default primary-btn radius-0">Jetzt Kaufen</a>
                                        </div>
                                        <div class="border-product">
                                            <h6 class="product-title">Lead-Liste {{ searchResult.industry}} – Ihre Lösung für gezielte B2B-Akquise</h6>
                                            <p>
                                                <br>
                                                Erwerben Sie die aktuelle Lead-Liste {{ searchResult.displayTableName}} und erhalten Sie alle relevanten Unternehmensdaten <br>
                                                aus dem Bereich {{searchResult.industry}} im Bundesland {{searchResult.region}} .<br>
                                                Diese Liste wurde speziell für Unternehmen entwickelt, die gezielt Geschäftskontakte aufbauen und expandieren möchten. <br>

                                                Aktualisiert am: {{searchResult.age}} <br>

                                                Die Liste umfasst folgende Spalten:
                                                <br>
                                                <br>
                                                Firmen Name <br>
                                                Adresse <br>
                                                Postleizahl <br>
                                                Website <br>
                                                Email Adressen <br>
                                                Telefon Nummer <br>
                                                <br>
                                                <br>
                                                Profitieren Sie von detaillierten und aktuellen Informationen, um Ihre Akquise effizient und zielgerichtet zu gestalten.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 rtl-text">
                                </div>
                            </div>
                        </div>
                        <section>
                            <h1>Vorschau</h1>
                            <table mat-table [dataSource]="tableColumns" class="mat-elevation-z8">
                                <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                                    <th mat-header-cell *matHeaderCellDef> {{ column | titlecase }} </th>
                                    <td mat-cell *matCellDef="let element"> {{ element[column] }} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </section>
                        <!--
                        <section class="login-popup">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 offset-md-3">
                                        <div class="text-center">
                                            <h4 class="m-b-20">Tabellen Vorschau anzeigen</h4>
                                            <a class="popup-with-form btn btn-default primary-btn"
                                               (click)="openVerticallyCentered(content)">Hier Klicken
                                            </a>
                                            <ng-template #content let-modal>
                                                <div class="login-modal">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <button class="close" type="button"
                                                                    (click)="modal.dismiss('Cross click')">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body text-center">
                                                            <h3 class="m-b-20">Tabellen Vorschau</h3>
                                                            <img [src]="imagePath" alt="Sample Image" class="modal-image">
                                                            <button class="btn btn-default primary-btn m-t-20" (click)="modal.dismiss('Cross click')">Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        -->
                        <section class="tab-product m-0 p-b-0">
                            <div class="row">
                                <div class="col-sm-12 col-lg-12">
                                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                        <li [ngbNavItem]="2">
                                            <button ngbNavLink>Inhalt</button>
                                            <ng-template ngbNavContent>
                                                <div class="single-product-tables">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td>Branche</td>
                                                            <td>{{ searchResult.industry }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Region</td>
                                                            <td>{{ searchResult.region }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Zeilenanzahl</td>
                                                            <td>{{ searchResult.rowCount }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Erstell Datum</td>
                                                            <td>{{ searchResult.age }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td>Firmen Name:</td>
                                                            <td>Name der Firma</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Adresse:</td>
                                                            <td>Addresse des Geschäftssitzes</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Postleizahl:</td>
                                                            <td>Postleizahl des Unternehmens</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Website:</td>
                                                            <td>Offizielle Website des Unternehmens</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email Adressen:</td>
                                                            <td>Email Adresse(n) des Unternehmens</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Telefon Nummer:</td>
                                                            <td>Offizielle Telefon Nr des Unternehmens</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ng-template>
                                        </li>
                                    </ul>
                                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-testimonial></app-testimonial>
</div>
