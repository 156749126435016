<!-- impressum.component.html -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain ">
                    <div>
                        <h2 class="breadcrumb-txt">Obrecht Industries</h2>
                    </div>
                    <div>
                        <ul>
                            <li><a href="#">Infos</a></li>
                            <li><a href="#"><i class="fa fa-angle-double-right"></i> Impressum </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="impressum-container">
    <h1>Impressum</h1>
    <div class="content">
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
            Mustermann GmbH<br />
            Musterstraße 1<br />
            12345 Musterstadt
        </p>

        <h2>Vertreten durch:</h2>
        <p>The Prince of Darkness</p>

        <h2>Kontakt:</h2>
        <p>
            Telefon: +49 (0)123 456789<br />
            E-Mail: <a href="mailto:info@mustermann.de">info(at)mustermann.de</a>
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE123456789</p>

        <h2>Haftungsausschluss</h2>
        <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links.
            Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>
    </div>
</section>
