<section class="p-b-0">
    <section class="agency breadcrumb-section-main breadcrumb-back-none inner-1 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="col-12">
                        <h2 class="breadcrumb-txt text-center">Profil</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="top-banner-wrapper">
                                        <div class="top-banner-content small-section">
                                            <h4>Willkommen zurück {{ user_name }}</h4>
                                            <h5>Hier findest du alle deine Bestellten Tabellen</h5>
                                            <p>
                                                Hier kannst du alle deine bestellten Tabellen im Excel- und CSV-Format herunterladen. Außerdem erhältst du einen Überblick über den aktuellen Status deiner Bestellungen. <br>
                                                Bei Fragen stehen wir dir jederzeit zur Verfügung!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="agency blog blog-sec blog-list">
    <div class="container">
        <div class="row">
            <div class="col-12 blog-sec blog-list shadow-lg p-3 mb-4" *ngFor="let item of searchResults">
                <div class="card-header">
                    <div class="left">
                        <h3>{{ item.industry }}</h3>
                    </div>
                    <div class="right">
                        <span class="status {{ item.status | lowercase }}">{{ item.status }}</span>
                    </div>
                </div>
                <div class="card-body">
                    <div class="info-group">
                        <div>
                            <strong>Industry:</strong>
                            <p>{{ item.industry }}</p>
                        </div>
                        <div>
                            <strong>Region:</strong>
                            <p>{{ item.region }}</p>
                        </div>
                        <div>
                            <strong>Row Count:</strong>
                            <p>{{ item.row_count }}</p>
                        </div>
                        <div>
                            <strong>Price:</strong>
                            <p>{{ item.price }} €</p>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <button class="btn btn-primary btn-sm" (click)="download_csv(item.table_name)">CSV</button>
                    <button class="btn btn-success btn-sm ml-2" (click)="download_excel(item.table_name)">Excel</button>
                </div>
            </div>
            <button class="btn primary-btn btn-spacing-2 logout-button" (click)="logout()">
                Logout
            </button>
        </div>
    </div>

</section>

<!--
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="top-banner-wrapper">
                                        <div class="top-banner-content small-section">
                                            <h5>Status:</h5>
                                            <p>- Status 1</p>
                                            <p>- Status 2</p>
                                            <p>- Status 3</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->

