<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Registrieren</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a href="#">Sign up</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>Registrieren</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>Account Erstellen</h3>
                <div class="theme-card">
                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="theme-form">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="username">Nutzername</label>
                                <input type="text" class="form-control" id="username" placeholder="Nutzername" formControlName="username" required="">
                                <div *ngIf="registerForm.get('username')?.invalid && registerForm.get('username')?.touched" class="error-text">
                                    Nutzername wird benötigt.
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="email">E-Mail</label>
                                <input type="text" class="form-control" id="email" placeholder="Email" formControlName="email" required="">
                                <div *ngIf="registerForm.get('email')?.invalid && registerForm.get('email')?.touched" class="error-text">
                                    <span *ngIf="registerForm.get('email')?.errors?.['required']">E-Mail wird benötigt.</span>
                                    <span *ngIf="registerForm.get('email')?.errors?.['email']">Ungültige E-Mail-Adresse.</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="password">Passwort</label>
                                <input type="password" class="form-control" id="password" placeholder="Passwort" formControlName="password" required="">
                                <div *ngIf="registerForm.get('password')?.invalid && registerForm.get('password')?.touched" class="error-text">
                                    <span *ngIf="registerForm.get('password')?.errors?.['required']">Passwort wird benötigt.</span>
                                    <span *ngIf="registerForm.get('password')?.errors?.['minlength']">Passwort muss mindestens 6 Zeichen lang sein.</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="password">Passwort Wiederholen</label>
                                <input type="password" class="form-control" id="password_repeat" placeholder="Passwort" formControlName="password_repeat" required="">
                                <div *ngIf="registerForm.get('password_repeat')?.invalid && registerForm.get('password_repeat')?.touched" class="error-text">
                                    <span *ngIf="registerForm.get('password_repeat')?.errors?.['required']">Passwort wiederholen wird benötigt.</span>
                                    <span *ngIf="registerForm.get('password_repeat')?.errors?.['mustMatch']">Passwörter stimmen nicht überein.</span>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn primary-btn btn-default radius-0 w-auto">Account Erstellen</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
