<header   [ngClass]="{
    'saas2': true,
    'variable-background-color': !isRootRoute,
    'loding-header': true,
    'nav-abs': true,
    'custom-scroll': true
  }">>
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="../../../../assets/images/Own/Palladio_h_40.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
