import { Component } from '@angular/core';
import { AuthService } from '../../../Services/BuissnessLogic/auth.service';
import { Router } from '@angular/router';
import {ColorScssService} from '../../../shared/service/color-scss.service';

@Component({
  selector: 'app-thankyou-for-order',
  standalone: true,
  imports: [],
  templateUrl: './thankyou-for-order.component.html',
  styleUrl: './thankyou-for-order.component.scss'
})
export class ThankyouForOrderComponent {
  email: string = ""
  constructor(private router: Router,
              private auth: AuthService,
              private color: ColorScssService
  ) {
    color.resetColorScheme()
    this.email = auth.email;
  }

  goToProfile() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/profil']);
      return
    }
    this.router.navigate(['/registrieren']);
  }
}
