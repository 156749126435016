import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {SEO_DATA} from './seo-data';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private meta: Meta, private titleService: Title) {}

  setMetaData(page: string) {
    const seoTags = SEO_DATA[page];
    if (seoTags) {
      this.titleService.setTitle(seoTags.title);
      this.meta.updateTag({ name: 'description', content: seoTags.description });
      this.meta.updateTag({ name: 'keywords', content: seoTags.keywords });
      this.meta.updateTag({ property: 'og:title', content: seoTags.ogTitle });
      this.meta.updateTag({ property: 'og:description', content: seoTags.ogDescription });
    }
  }
}
