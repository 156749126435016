import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
   type?: string;
   icon?:string;
	badgeType?: string;
	badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {   }

  MENUITEMS: Menu[] = [
      {
          title: 'Tabellen', type: 'sub', children: [
              { path: '/vorhandene-tabellen', title: 'Vorhandene Tabellen',  type: 'link' },
          ]
      },
      {
          title: 'Erstellen', type: 'sub', children: [
              { path: '/tabelle-erstellen', title: 'Tabelle Erstellen',  type: 'link' },
          ]
      },
      {
          title: 'Kontakt', type: 'sub', children: [
              { path: '/kontakt', title: 'Kontakt',  type: 'link' },
              { path: '/services', title: 'Services',  type: 'link' },
              { path: '/impressum', title: 'Impressum',  type: 'link' },
          ]
      },
   ]
  
  	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
   
}
