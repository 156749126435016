import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  image_path: string = "TablePreviews/"

  constructor() { }

  getImage(filename: string): string {
    return this.image_path + filename + ".png";
  }
}
