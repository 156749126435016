import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../Services/BuissnessLogic/auth.service';
import {ColorScssService} from '../../../shared/service/color-scss.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-enterprice-sass-nav',
    templateUrl: './enterprice-sass-nav.component.html',
    styleUrls: ['./enterprice-sass-nav.component.scss']
})
export class EnterpriceSassNavComponent implements OnInit {
    private userIdSubscription: Subscription | null = null;
    isLoggedIn: boolean = false;
    isRootRoute: boolean = false;

    constructor(private auth: AuthService,
                private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.userIdSubscription = this.auth.userId$.subscribe(userId => {
            this.isLoggedIn = !!userId;
        });

        this.isRootRoute = this.router.url === '/';

        // Subscribe to router events to handle navigation changes
        this.router.events.subscribe(() => {
            this.isRootRoute = this.router.url === '/';
        });
    }
}
