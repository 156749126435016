<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain ">
                    <div>
                        <h2 class="breadcrumb-txt">Order Complete</h2>
                    </div>
                    <div>
                        <ul>

                            <li><a href="#">Kauf abgeschlossen</a></li>
                            <li><a href="#"><i class="fa fa-angle-double-right"></i></a>Thank You</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="thank-you-container">
    <div class="thank-you-card">
        <h1>Vielen Dank für Ihre Bestellung!</h1>
        <p class="intro-text">
            Ihre Bestellung war erfolgreich und wir freuen uns, Ihnen mitteilen zu können, dass die Rechnung an
            <strong>{{ email }}</strong> gesendet wurde.
        </p>
        <p class="check-email">
            Bitte überprüfen Sie Ihren Posteingang, um die Rechnung einzusehen.
        </p>
        <p class="gratitude">
            Wir schätzen Ihr Vertrauen in uns und bedanken uns herzlich für Ihren Einkauf.
        </p>
        <p class="order-status">
            Den aktuellen Status Ihrer Bestellung können Sie jederzeit in Ihrem
            <a href="#" (click)="goToProfile(); $event.preventDefault()">Profil</a> einsehen.
        </p>
        <p class="support">
            Falls Sie Fragen haben oder Unterstützung benötigen, zögern Sie nicht, sich jederzeit an unseren
            <a href="/kontakt">Kundensupport</a> zu wenden.
        </p>
    </div>
</div>
