import {Component, Input, OnInit} from '@angular/core';


interface FaqItem {
    question: string;
    answer: string;
}

@Component({
    selector: 'app-enterprice-sass-faq',
    templateUrl: './enterprice-sass-faq.component.html',
    styleUrls: ['./enterprice-sass-faq.component.scss']
})
export class EnterpriceSassFaqComponent implements OnInit {

    @Input() datasetIdentifier: string = '';
    public selectedItems: FaqItem[] = [];

    public short_variant: FaqItem[] = [
        {
            question: 'Ist der Kauf sicher und wie erfolgt die Bezahlung?',
            answer: 'Ja, der Kaufprozess ist sicher und verschlüsselt. Die Bezahlung erfolgt per Banküberweisung, und nach Zahlungseingang erhalten Sie innerhalb eines Tages Zugriff auf Ihre gekauften Daten.'
        },
        {   question: 'Wie werden die Daten gesammelt?',
            answer: 'Wir verwenden eine Kombination aus öffentlich zugänglichen Quellen und speziellen Datenbanken, die eine hohe Genauigkeit und Aktualität gewährleisten.'},
        {
            question: 'In welchen Formaten kann ich die Daten exportieren?',
            answer: 'Sie können die Daten in verschiedenen Formaten wie CSV oder Excel exportieren und so flexibel in Ihre eigenen Systeme integrieren.'
        },
    ];

    public long_variant: FaqItem[] = [
        {
            question: 'Ist der Kauf sicher und wie erfolgt die Bezahlung?',
            answer: 'Ja, der Kaufprozess ist sicher und verschlüsselt. Die Bezahlung erfolgt per Banküberweisung, und nach Zahlungseingang erhalten Sie innerhalb eines Tages Zugriff auf Ihre gekauften Daten.'
        },
        {
            question: 'Wie werden die Daten gesammelt?',
            answer: 'Wir verwenden eine Kombination aus öffentlich zugänglichen Quellen und speziellen Datenbanken, die eine hohe Genauigkeit und Aktualität gewährleisten.'
        },
        {
            question: 'In welchen Formaten kann ich die Daten exportieren?',
            answer: 'Sie können die Daten in verschiedenen Formaten wie CSV oder Excel exportieren und so flexibel in Ihre eigenen Systeme integrieren.'
        },
        {
            question: 'Wie oft werden die Daten aktualisiert?',
            answer: 'Unsere Datenbanken werden regelmäßig aktualisiert, um sicherzustellen, dass Sie Zugriff auf die neuesten und relevantesten Informationen haben.'
        },
        {
            question: 'Darf ich die Daten an Dritte weitergeben?',
            answer: 'Nein, die Weitergabe der Daten an Dritte ist nicht gestattet, es sei denn, Sie haben vorher eine schriftliche Genehmigung von uns erhalten.'
        },
        {
            question: 'Gibt es eine Rückerstattungsrichtlinie?',
            answer: 'Da es sich um digitale Produkte handelt, können wir keine Rückerstattungen anbieten. Bitte stellen Sie sicher, dass Sie die benötigten Daten vor dem Kauf überprüfen.'
        },
        {
            question: 'Sind die Daten DSGVO-konform?',
            answer: 'Ja, alle Daten werden im Einklang mit der DSGVO erhoben und verarbeitet. Bitte verwenden Sie die Daten ebenfalls im Rahmen der gesetzlichen Vorschriften.'
        },
        {
            question: 'Wie kann ich Support erhalten?',
            answer: 'Unser Support-Team steht Ihnen per E-Mail oder über unser Kontaktformular zur Verfügung. Wir antworten in der Regel innerhalb von 24 Stunden.'
        },
        {
            question: 'Welche Branchen sind in den Datensätzen verfügbar?',
            answer: 'Unsere Daten umfassen eine Vielzahl von Branchen, darunter IT, Bauwesen, Handel, Dienstleistungen und viele mehr. Kontaktieren Sie uns für genauere Informationen.'
        },
        {
            question: 'Gibt es Rabatte für größere Datenkäufe?',
            answer: 'Ja, wir bieten Rabatte für größere Datenmengen an. Bitte kontaktieren Sie uns, um ein individuelles Angebot zu erhalten.'
        }
    ];


    constructor() {
    }

    ngOnInit() {
        // Decide the dataset based on the identifier
        this.selectedItems = this.datasetIdentifier === 'short' ? this.short_variant : this.long_variant;
    }
}
