<header class="saas1 header-fixed loding-header position-absolute custom-scroll nav-lg" style="background-color: #071828;">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="/" class="d-inline-block m-r-auto">
                        <img src="../../../../assets/images/Own/Palladio_h_40.png" alt="" class="img-fluid">
                    </a>
                    <div class="nav-buttons" style="float: right;">
                        <a [routerLink]="['/kontakt']" style="text-decoration: none;">
                            <button type="button" class="btn primary-btn btn-spacing-1 nav-button">Kontakt</button>
                        </a>
                        <a [routerLink]="['/tabelle-erstellen']" style="text-decoration: none;">
                            <button type="button" class="btn primary-btn btn-spacing-1 nav-button">Erstellen</button>
                        </a>
                        <a [routerLink]="['/scroll_view']" style="text-decoration: none;">
                            <button type="button" class="btn primary-btn btn-spacing-2 nav-button">Tabellen</button>
                        </a>
                        <a *ngIf="!isLoggedIn" [routerLink]="['/login']" style="text-decoration: none;">
                            <button type="button" class="btn primary-btn btn-spacing-2 custom-height login-button">Login</button>
                        </a>
                        <a *ngIf="isLoggedIn" [routerLink]="['/profile']" style="text-decoration: none;">
                            <button class="login-button btn custom-height btn-spacing-2">
                                <i class="fa fa-user user-icon"></i>
                            </button>
                        </a>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
