import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


@Component({
  selector: 'app-search-dropdown-menu',
  templateUrl: './search-dropdown-menu.component.html',
  styleUrl: './search-dropdown-menu.component.scss'
})
export class SearchDropdownMenuComponent implements OnInit {
  myControl = new FormControl('');
  filteredOptions: Observable<string[]> | undefined;
  currentValue: string = '';

  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();

  options: string[] = ['Elektriker', 'Schweißer', 'Maler', 'Architect'];

  ngOnInit() {
    // Capture initial value
      this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''), // Start with an empty string to show all options initially
          map(value => this._filter(value || ''))
      );


      this.currentValue = this.myControl.value ?? '';

    // Set up filteredOptions observable
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
    );

    // Subscribe to valueChanges to emit and update currentValue as it changes
    this.myControl.valueChanges.subscribe(value => {
      this.currentValue = value ?? '';
      this.valueChanged.emit(this.currentValue); // Emit the selected value
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}
