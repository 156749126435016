import {Component, OnInit} from '@angular/core';
import {blogBasicDB} from '../../shared/data/blog/blog-basic/list';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../Services/BuissnessLogic/auth.service';
import {Router} from '@angular/router';
import {FileService} from '../../Services/file.service';
import {environment} from '../../environments/environments';
import {SearchResult} from '../../CustomComponents/Objects/SearchResult';
import {ColorScssService} from '../../shared/service/color-scss.service';

@Component({
    selector: 'app-list-no-sidebar',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    private apiUrl = environment.apiUrl + '/get-user-tables';
    searchResults: SearchResult[] = [];
    user_name: string = '';

    constructor(private http: HttpClient,
                private auth: AuthService,
                private router: Router,
                private file: FileService,
                private color: ColorScssService
    ) {
        color.resetColorScheme();
        if (!auth.isLoggedIn()) {
            this.router.navigate(['/anmelden']);
            return;
        }
        this.user_name = auth.user_name;
    }

    ngOnInit() {
        this.fetchUserTable();
    }

    fetchUserTable() {
        const payload = {
            user_id: this.auth.getUserId(),
        };

        this.http.post<{ message: string, data: SearchResult[] }>(this.apiUrl, payload).subscribe(
            (response) => {
                this.searchResults = response.data;
                console.log('Search results retrieved:', this.searchResults);
                console.log('Response message:', response.message);
            },
            (error) => {
                console.error('Error retrieving search results:', error);
            }
        );
    }

    // send userID,
    download_excel(table_name: string) {
        this.download_arbit_file(table_name, '.xlsx');
    }

    download_csv(table_name: string) {
        this.download_arbit_file(table_name, '.csv');
    }

    download_arbit_file(table_name: string, file_type: string) {
        const user_id = this.auth.getUserId();

        this.file.download_file(user_id, table_name, file_type).subscribe(
            (blob: Blob) => {
                // Handle successful download here, e.g., saving the file locally
                const fileUrl = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = fileUrl;
                anchor.download = table_name;
                anchor.click();
                window.URL.revokeObjectURL(fileUrl); // Clean up after file download
            },
            (error) => {
                console.error('Error during file download:', error);
            }
        );
    }

    logout() {
        this.auth.logout();
        this.router.navigate(["/"])
    }
}
