<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain ">
                    <div>
                        <h2 class="breadcrumb-txt">Tabelle Erstellen</h2>
                    </div>
                    <div>
                        <ul>
                            <li><a href="#">Tabellen</a></li>
                            <li><a href="#"><i class="fa fa-angle-double-right"></i>Tabelle generieren</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="p-b-0">
    <div class="container">
        <div class="row addit-space">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>Tabelle Generieren</span></h2>
                    </div>
                </div>
                <div class="top-banner-content small-section">
                    <h5>Erstellen Sie Ihre individuelle Lead-Liste</h5>
                    <p>Nutzen Sie unser Tool, um personalisierte Lead-Listen zu generieren, die genau auf die Anforderungen Ihres Unternehmens zugeschnitten sind. Mit unserem Service erhalten Sie schnellen Zugang zu wertvollen Kontakten, die Ihnen helfen, neue Kunden zu gewinnen und Ihre Geschäftsziele zu erreichen. Starten Sie jetzt und setzen Sie Ihre Kampagnen mit den richtigen Leads erfolgreich um.</p>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <h4>Branche</h4>
                        <app-search-dropdown-menu (valueChanged)="onDropdownValueChanged_Industry($event)"></app-search-dropdown-menu>
                        <h4>Bundesland</h4>
                        <app-search-menu-regions (valueChanged)="onDropdownValueChanged_Region($event)"></app-search-menu-regions>
                    </div>
                    <!-- Right Column -->
                    <div class="col-md-6">
                        <div class="quantity-selector" >
                            <h4>Zeilenanzahl</h4>
                            <span class="mengen-info">Stelle hier die Zeilenanzahl ein:</span>
                            <!-- -->
                            <div class="quantity-selector-element">
                                <button class="btn primary-btn nav-button" (click)="decrement()" [disabled]="selected_row_count <= 1000">-</button>
                                <input type="number" [value]="selected_row_count" (input)="onQuantityChange($event)" />
                                <button class="btn primary-btn nav-button" (click)="increment()">+</button>
                            </div>
                            <p *ngIf="selected_row_count < 1000" class="warning-text">Mindestanzahl: 1000</p>
                            <p *ngIf="selected_row_count > 10000" class="warning-text">Maximalanzahl: 10.000</p>
                            <div class="info-line">
                                <svg class="info-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <circle cx="12" cy="12" r="10"/>
                                    <line x1="12" y1="16" x2="12" y2="12"/>
                                    <line x1="12" y1="8" x2="12" y2="8"/>
                                </svg>
                                <span class="info-text">Falls weniger Firmen existieren als ausgewählt <br> wird die differenz erstattet</span>
                            </div>
                        </div>
                    </div>
                    <!-- Price Elements -->
                    <section class="resume pricing bg-pink">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="title title2 title-inner">
                                        <div class="main-title">
                                            <h2 class="font-primary borders text-center main-text m-b-0 text-uppercase"><span>Spalten auswahl</span></h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <owl-carousel-o [options]="pricingcarouselOptions2" class="pricing-slider">
                                        <ng-container *ngFor="let price of pricing2">
                                            <ng-template carouselSlide class="item">
                                                <div class="price-container price-margin shadows bg-white text-center">
                                                    <div class="price-feature-container set-relative">
                                                        <div class="feature-text">
                                                            <span class="flaticon-{{price.icon}} feature-icon"></span>
                                                            <h4 class="feature-text-heading bold text-uppercase">{{price.package}}</h4>
                                                            <hr class="set-border">
                                                        </div>
                                                        <div class="price-features">
                                                            <h5 class="price-feature text-center">{{price.feature1}}</h5>
                                                            <h5 class="price-feature text-center">{{price.feature2}}</h5>
                                                            <h5 class="price-feature text-center">{{price.feature3}}</h5>
                                                            <h5 class="price-feature text-center">{{price.feature4}}</h5>
                                                            <h5 class="price-feature text-center">{{price.feature5}}</h5>
                                                            <h5 class="price-feature text-center">{{price.feature6}}</h5>
                                                            <h5 class="price-feature text-center">{{price.feature7}}</h5>
                                                        </div>
                                                        <div class="price-value">
                                                            <h6 class="price text-center"><span class="large">{{price.price}}</span>€/Lead</h6>
                                                        </div>
                                                        <a class="btn btn-default back-white" (click)="goToCheckOut(price.id)">{{price.btn}}</a>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>
