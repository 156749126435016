<div class="agency">
    <!--breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Checkout</h2>
                        </div>
                        <div>
                            <ul>

                                <li><a href="#">Kauf abschließen</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>{{ table_name }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end -->
    <section class="section-b-space">
        <div class="container padding-cls">
            <div class="checkout-page">
                <div class="checkout-form">
                    <form [formGroup]="checkoutForm">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12 col-xs-12">
                                <div class="checkout-title">
                                    <h3>Zahlungs Informationen</h3>
                                </div>
                                <div class="row check-out">
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <div class="field-label">Vorname *</div>
                                        <input type="text" name="firstname"
                                               [formControl]="checkoutForm.get('firstname')" placeholder=""
                                               autocomplete="off">
                                        <div *ngIf="(submitted || checkoutForm.get('firstname')?.touched) && checkoutForm.get('firstname')?.errors?.required"
                                             class="text text-danger">
                                            Vorname wird benötigt.
                                        </div>
                                        <div *ngIf="(submitted || checkoutForm.get('firstname')?.touched) && checkoutForm.get('firstname')?.errors?.pattern"
                                             class="text text-danger">
                                            Vorname darf nur Buchstaben enthalten.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <div class="field-label">Nachname *</div>
                                        <input type="text" name="lastname"
                                               [formControl]="checkoutForm.controls['lastname']" value="" placeholder=""
                                               autocomplete="off">
                                        <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.required"
                                             class="text text-danger">
                                            Nachname wird benötigt.
                                        </div>
                                        <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.pattern"
                                             class="text text-danger">
                                            Nachname darf nur Buchstaben enthalten.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <div class="field-label">Tel. Nummer *</div>
                                        <input type="text" name="phone" [formControl]="checkoutForm.controls['phone']"
                                               value="" placeholder="" autocomplete="off">
                                        <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.required"
                                             class="text text-danger">
                                            Phone Nr wird benötigt.
                                        </div>
                                        <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.pattern"
                                             class="text text-danger">
                                            Phone Nr muss eine Nummer sein.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                        <div class="field-label">Email *</div>
                                        <input type="text" name="email" [formControl]="checkoutForm.controls['email']"
                                               value="" placeholder="" autocomplete="off">
                                        <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.required"
                                             class="text text-danger">
                                            E-Mail Adresse wird benötigt.
                                        </div>
                                        <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.email"
                                             class="text text-danger">
                                            Ungültige E-Mail Adresse.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div class="field-label">Land *</div>
                                        <select name="country" [formControl]="checkoutForm.controls['country']">
                                            <option value="" selected>Wähle dein Land aus</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Andere">Andere</option>
                                        </select>
                                        <div *ngIf="checkoutForm.controls.country.touched && checkoutForm.controls.country.errors?.required"
                                             class="text text-danger">
                                            Land wird benötigt.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div class="field-label">Adresse *</div>
                                        <input type="text" name="address"
                                               [formControl]="checkoutForm.controls['address']" value="" placeholder=""
                                               autocomplete="off">
                                        <div *ngIf="checkoutForm.controls.address.touched && checkoutForm.controls.address.errors?.required"
                                             class="text text-danger">
                                            Adresse wird benötigt.
                                        </div>
                                        <div *ngIf="checkoutForm.controls.address.touched && checkoutForm.controls.address.errors?.maxlength"
                                             class="text text-danger">
                                            Maximal 50 Zeichen.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                        <div class="field-label">Stadt *</div>
                                        <input type="text" name="town" [formControl]="checkoutForm.controls['town']"
                                               value="" placeholder="" autocomplete="off">
                                        <div *ngIf="checkoutForm.controls.town.touched && checkoutForm.controls.town.errors?.required"
                                             class="text text-danger">
                                            Stadt wird benötigt.
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                        <div class="field-label">Postleizahl</div>
                                        <input type="text" name="postalcode"
                                               [formControl]="checkoutForm.controls['postalcode']" value=""
                                               placeholder=""
                                               autocomplete="off">
                                    </div>
                                    <p>* benötigte Informationen</p>
                                </div>
                            </div>
                            <!-- right side -->
                            <div class="col-lg-6 col-sm-12 col-xs-12">
                                <div class="checkout-details">
                                    <div class="order-box">
                                        <div class="title-box">
                                            <div>Produkt <span> Total</span></div>
                                        </div>
                                        <ul class="qty" *ngIf='checkout_items.length'>
                                            <li *ngFor="let item of checkout_items">
                                                {{ item.name }} <span>{{ item.price + " €" }} </span>
                                            </li>
                                        </ul>
                                        <ul class="qty" *ngIf='isEmpty'>
                                            <li class="empty-checkout"> There are no products in cart</li>
                                        </ul>
                                        <ul class="sub-total">
                                            <li>
                                                Subtotal <span class="count">{{ total_price + " €" }}</span>
                                            </li>
                                        </ul>
                                        <ul class="total">
                                            <li>Total <span class="count">{{ total_price + " €" }}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="checkout-details">
                                    <div class="order-box">
                                        <h4>Banküberweisung</h4>
                                        <p>
                                            Nach Abschluss Ihres Kaufs erhalten Sie eine E-Mail mit den Zahlungsdetails.
                                            Bitte überweisen Sie den Betrag auf das angegebene Konto und geben Sie die
                                            Referenznummer aus der E-Mail im Verwendungszweck an, damit wir Ihre Zahlung
                                            korrekt zuordnen können.
                                        </p>
                                        <div class="buy-now-button-container">
                                            <button class="btn btn-default primary-btn success  radius-0 back-white"
                                                    type="submit" (click)="onSubmitBank()">
                                                Jetzt Kaufen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="checkout-details">
                                    <div class="order-box">
                                        <h4>PayPal</h4>
                                        <!-- FIXME ADD MARGIN AND DESC -->
                                        <div id="paypal-button-container" class="paypal-checkout-component"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>
