import { Component, OnInit } from '@angular/core';
import { Menu, NavService } from '../../../shared/service/nav.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../Services/BuissnessLogic/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  private userIdSubscription: Subscription | null = null;
  public  isLoggedIn: boolean = false;
  public menuItems: Menu[];
  public openSide : boolean = false;
  public activeItem: string = 'home';
  public active: boolean = false;
  public activeChildItem : string = '' 
  public overlay: boolean = false;
  
  constructor( public navServices: NavService,
               private auth: AuthService,
               private router: Router,
               ) {
  }
 
  ngOnInit() {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
    });

    this.userIdSubscription = this.auth.userId$.subscribe(userId => {
      this.isLoggedIn = !!userId;
    });
  }

  toggleSidebar(){
    this.openSide = !this.openSide
  }

  closeOverlay(){
    this.openSide = false
  }

  //For Active Main menu in Mobile View
  setActive(menuItem){
    if (this.activeItem === menuItem) {
      this.activeItem = ''
    } else {
      this.activeItem = menuItem
      // If the menu item has children, navigate to the first valid child link
      if (menuItem.children && menuItem.children.length > 0) {
        const firstChild = menuItem.children.find(child => child.type === 'link' && child.path);
        if (firstChild) {
          this.router.navigate([firstChild.path]);
        }
      }
    }
  }

  isActive(item){
    return this.activeItem === item 
  }

  // For Active Child Menu in Mobile View
  setChildActive(subMenu){
    if (this.activeChildItem === subMenu) {
      this.activeChildItem = ''
    } else {
      this.activeChildItem = subMenu
    }
  }

  ischildActive(subMenu){
    return this.activeChildItem === subMenu 
  }


}
