<!-- header section Start-->
<section class="saas1 header" id="home">
    <div class="saas1-header bg header8-content"  [ngStyle]="{'background-image': 'url(assets/images/saas1/slider-banner.jpg)'}">
        <div class="container">
            <div class="row">
                <div class="col-md-7">
                    <div class="center-text">
                        <div>
                            <div class="header-text">
                                <div class="d-flex">
                                    <h1><span class="theme-color">B</span>2<span class="small-text">
                                        </span><span class="theme-color">B L</span>eads</h1>
                                </div>
                            </div>
                            <div class="header-sub-text">
                                <h3 class="text-white">Email Addressen für ihre nächte<span> Email Marketing</span> Kampange</h3>
                            </div>
                            <div class="header-sub-text">
                                <h6>Ab <span class="theme-color"> $0.01 </span>per Lead</h6>
                            </div>
                            <div class="link-horizontal">
                                <ul>
                                    <li>
                                        <a class="btn btn-default primary-btn transparent" routerLink="/vorhandene-tabellen">
                                            Tabellen Durchsuchen
                                            <img src="../../../../assets/images/saas1/video-icon.png" alt="video-icon" class="m-l-5">
                                        </a>
                                    </li>

                                    <!--
                                    <li>
                                        <a class="btn btn-default primary-btn transparent" routerLink="/kontakt">Kontakt</a>
                                    </li>
                                    -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!--
                <div class="">
                    <div class="center-text slider-banner">
                        <img src="../../../../assets/images/Own/Unbenannt2.png" alt="slid-banner">
                    </div>
                </div>
                -->
            </div>
        </div>
    </div>
    <img src="../../../../assets/images/saas1/background2.png" class="img-fluid set-abs background" alt="">
    <img src="../../../../assets/images/saas1/dot.png" class="img-fluid set-abs dot" alt="">
</section>
<!-- header section end-->
