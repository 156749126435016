import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-services-listing',
  templateUrl: './services-listing.component.html',
  styleUrl: './services-listing.component.scss'
})
export class ServicesListingComponent implements OnInit {

  fadeInRight: any;

  constructor() { }

  ngOnInit() {
  }

}
