import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testemonial.component.html',
  styleUrl: './testemonial.component.scss'
})
export class TestemonialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  getStars(rating: number): string[] {
    return Array.from({ length: 5 }, (_, i) => (i < rating ? 'yellow' : ''));
  }


  users = [
    {
      img:"assets/images/event/testimonial/L3-1.png",
      testimonial: 'When you innovate, you make mistakes. It is best to admit them quickly and get on with improving your other innovations.',
      name: 'John Denal',
      role: 'UI Designer',
      rating: 4, // Assuming a rating out of 5
    },
    {
      img:"assets/images/event/testimonial/L3-1.png",
      testimonial: 'Success usually comes to those who are too busy to be looking for it.',
      name: 'Jane Smith',
      role: 'Project Manager',
      rating: 5,
    },
  ];

  testimonialCarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: true,
    dots: false,
    navText: ['<img src="assets/images/Own/left.png">', '<img src="assets/images/Own/right.png">'],
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true
  }



}
