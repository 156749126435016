<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-contain ">
                    <div>
                        <h2 class="breadcrumb-txt">Kontakt</h2>
                    </div>
                    <div>
                        <ul>
                            <li><a href="#">Impressum</a></li>
                            <li><a href="#"><i class="fa fa-angle-double-right"></i>Kontakt</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>Kontaktiere uns</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="music bg-footer" [ngStyle]="{'background-image': 'url(assets/images/music/footer-bg.jpg)'}">
    <div class="container">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="form form-footer p-t-50">
                <div class="row">

                    <div class="col-sm-6">
                        <div class="form-group">
                            <input type="text" placeholder="Vorname" formControlName="firstName">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <input type="text" placeholder="Nachname" formControlName="lastName">
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group">
                            <input type="text" placeholder="Email" formControlName="email">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <input type="text" placeholder="Firmen Name" formControlName="companyName">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <textarea class="form-control" rows="12" placeholder="Nachricht" formControlName="message"></textarea>
                        </div>
                    </div>
                    <div class="booking">
                        <button type="submit" class="btn btn-default primary-btn m-0-auto bg-black">Send</button>
                    </div>
                </div>
            </div>
        </form>

    </div>
</section>
