import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchResult} from '../../../CustomComponents/Objects/SearchResult';
import {AuthService} from '../../../Services/BuissnessLogic/auth.service';
import {SearchService} from '../../../Services/BuissnessLogic/search.service';
import {ImageService} from '../../../Services/image.service';
import {ColorScssService} from '../../../shared/service/color-scss.service';
import {InteliRouteService} from '../../../Services/inteli-route.service';
import {SeoService} from '../../../Services/SEO/seo.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-ItemView',
    templateUrl: './ItemView.html',
    styleUrls: ['./ItemView.scss']
})
export class ItemView implements OnInit {
    public active = 2;
    imagePath: string;

    // extract suggested table name from the route parm
    public table_name_route: string = '';
    searchResult: SearchResult = {
        table_name: '',
        display_table_name: "",
        status: '',
        industry: '',
        region: '',
        age: '',
        row_count: 0,
        price: ''
    };

    constructor(private route: ActivatedRoute,
                private router: Router,
                private auth: AuthService,
                private searchService: SearchService,
                private imageService: ImageService,
                private seoService: SeoService,
                private intRoute: InteliRouteService,
                private modalService: NgbModal,
                private color: ColorScssService,
    ) {
        color.resetColorScheme()
        this.table_name_route = this.route.snapshot.paramMap.get('tableName');
    }

    ngOnInit() {
        this.getTableInfo();
        this.load_table_image()
    }

    openVerticallyCentered(content) {
        this.modalService.open(content);
    }

    load_table_image() {
        this.imagePath = this.imageService.getImage(this.table_name_route);
    }

    proceed_to_checkout() {
        if (!this.auth.isLoggedIn()) {
            this.intRoute.store_current_route()
            this.router.navigate(['/anmelden']);
            return;
        }
        this.router.navigate(['/checkout/' + this.table_name_route]);
    }

    getTableInfo() {
        const tableName = this.route.snapshot.paramMap.get('tableName');
        if (tableName) {
            this.searchService.getTableInfos(tableName).subscribe(
                (response) => {
                    this.searchResult = response.data;
                    this.seoService.setMetaDataItemView(this.searchResult)
                    console.log(this.searchResult);
                },
                (error) => {
                    console.error('Error retrieving table info:', error);
                }
            );
        } else {
            console.error('Table name is not provided.');
        }
    }
}
