import { provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule} from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ItemView} from './ProductSearch/ScrollExisting/ItemView/item-view';
import {NgbAccordionModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {ScrollView} from './ProductSearch/ScrollExisting/ScrollView/scroll-view';
import {SearchDropdownMenuComponent} from './CustomComponents/search-dropdown-menu/search-dropdown-menu.component';
import {ProfileComponent} from './UserPages/Profile/profile.component';
import {EnterpriceSassComponent} from './LandingPage/enterprice-sass/enterprice-sass.component';
import {EnterpriceSassNavComponent} from './LandingPage/enterprice-sass/enterprice-sass-nav/enterprice-sass-nav.component';
import {EnterpriceSassFaqComponent} from './LandingPage/enterprice-sass/enterprice-sass-faq/enterprice-sass-faq.component';
import {
    EnterpriceSassHeaderComponent
} from './LandingPage/enterprice-sass/enterprice-sass-header/enterprice-sass-header.component';
import {
    EnterpriceSassFeatureComponent
} from './LandingPage/enterprice-sass/enterprice-sass-feature/enterprice-sass-feature.component';
import {
    EnterpriceSassBuildComponent
} from './LandingPage/enterprice-sass/enterprice-sass-build/enterprice-sass-build.component';
import {
    EnterpriceSassCopyrightComponent
} from './LandingPage/enterprice-sass/enterprice-sass-copyright/enterprice-sass-copyright.component';
import {
    EnterpriceSassFooterComponent
} from './LandingPage/enterprice-sass/enterprice-sass-footer/enterprice-sass-footer.component';
import { GalleryComponent, GalleryModule } from 'ng-gallery';
import { CheckoutComponent } from './ProductSearch/Finalisation/checkout/checkout.component';
import { LoginComponent } from './UserPages/login/login.component';
import { SignUpComponent } from './UserPages/sign-up/sign-up.component';
import { PageNotFoundComponent } from './StaticSites/page-not-found/page-not-found.component';
import { ContactComponent } from './contact/contact.component';
import { ImpressumComponent } from './StaticSites/impressum/impressum.component';
import { SearchMenuRegionsComponent } from './CustomComponents/search-menu-regions/search-menu-regions.component';
import { GenerationPageComponent } from './ProductSearch/GenerationPage/generation-page/generation-page.component';
import { AboutPage } from './AboutPage/about-page.component';
import { ServicesListingComponent } from './AboutPage/services-listing/services-listing.component';
import { TestemonialComponent } from './AboutPage/testimonial/testemonial.component';
import { MenuComponent } from './LandingPage/NavigationMenu/menu/menu.component';
import {EnterpriceSassWorkComponent} from './LeadGeneration/lead-geneation-page/enterprice-sass-work/enterprice-sass-work.component';
import {LeadGeneationPageComponent} from './LeadGeneration/lead-geneation-page/lead-geneation-page.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SignUpComponent,
        ItemView,
        ScrollView,
        ProfileComponent,
        PageNotFoundComponent,
        ContactComponent,
        // Landing

        EnterpriceSassComponent,
        EnterpriceSassHeaderComponent,
        EnterpriceSassFeatureComponent,
        EnterpriceSassBuildComponent,
        EnterpriceSassFaqComponent,
        EnterpriceSassNavComponent,
        EnterpriceSassCopyrightComponent,
        EnterpriceSassFooterComponent,
        EnterpriceSassWorkComponent,

        GenerationPageComponent,
        CheckoutComponent,
        ImpressumComponent,
        SearchMenuRegionsComponent,
        SearchDropdownMenuComponent,
        AboutPage,
        ServicesListingComponent,
        TestemonialComponent,
        MenuComponent,
        LeadGeneationPageComponent,
    ],
    imports: [
        CommonModule,
        CarouselModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,

        NgbNavModule,
        NgbAccordionModule,
        GalleryComponent,
        GalleryModule,

        // Clean from here on later
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        RouterModule.forRoot(routes, {useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'}),
        FormsModule,
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        provideHttpClient(),
        provideAnimationsAsync(),
    ],
    bootstrap: [AppComponent],
    exports: [
        EnterpriceSassWorkComponent
    ]
})

export class AppModule {

}
