export const SEO_DATA = {
    homepage: {
        title: 'Willkommen bei Palladio - Ihre Quelle für B2B-Leads in Deutschland',
        description: 'Palladio bietet hochwertige B2B-Leads und umfassende Geschäftsdatenlösungen für den deutschen Markt. Steigern Sie Ihre Akquise mit unserer Hilfe.',
        keywords: 'B2B-Leads, Geschäftsdaten Deutschland, Lead-Generierung, Palladio, deutsche Unternehmen',
        ogTitle: 'Palladio - Hochwertige B2B-Leads in Deutschland',
        ogDescription: 'Optimieren Sie Ihre Lead-Akquise mit deutschen Geschäftsdaten von Palladio.'
    },
    scrollViewPage: {
        title: 'Über Uns - Palladio',
        description: 'Erfahren Sie mehr über Palladio, Ihren vertrauenswürdigen Partner für B2B-Datenlösungen in Deutschland.',
        keywords: 'über uns, Firmeninfo, B2B-Datenanbieter, Palladio',
        ogTitle: 'Über Palladio',
        ogDescription: 'Lernen Sie unsere Geschichte und unser Fachwissen in der Bereitstellung von B2B-Daten kennen.'
    },
    generateTablePage: {
        title: 'Datenübersicht - Palladio',
        description: 'Entdecken Sie unsere strukturierten B2B-Daten, die Ihnen helfen, gezielte Leads in Deutschland zu generieren.',
        keywords: 'B2B-Daten, Lead-Generierung, deutsche Unternehmen, Datentabellen',
        ogTitle: 'Datenübersicht - Palladio',
        ogDescription: 'Palladio bietet umfassende Daten, um Ihre Lead-Generierung in Deutschland zu unterstützen.'
    },
    kontaktPage: {
        title: 'Kontaktieren Sie Uns - Palladio',
        description: 'Nehmen Sie Kontakt mit Palladio auf und erfahren Sie mehr über unsere Dienstleistungen für die B2B-Lead-Generierung.',
        keywords: 'Kontakt, Palladio, B2B-Lead-Service, deutsche Geschäftsdaten',
        ogTitle: 'Kontakt - Palladio',
        ogDescription: 'Kontaktieren Sie Palladio, um mehr über unsere B2B-Lösungen in Deutschland zu erfahren.'
    },
    itemViewPage: {
        title: 'Lead-Details - Palladio',
        description: 'Erhalten Sie detaillierte Informationen zu unseren angebotenen Leads und erweitern Sie Ihre Geschäftsmöglichkeiten in Deutschland.',
        keywords: 'Lead-Details, B2B-Leads Deutschland, Geschäftsinfos',
        ogTitle: 'Lead-Details - Palladio',
        ogDescription: 'Erfahren Sie mehr über unsere spezifischen Leads und wie Palladio Ihr Wachstum fördern kann.'
    }
};
