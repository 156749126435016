import {Component, OnInit} from '@angular/core';
import {ColorScssService} from '../../../shared/service/color-scss.service';
import {ScrapingRequest} from '../../../CustomComponents/Objects/SearchResult';
import {CheckoutService} from '../../../Services/BuissnessLogic/checkout.service';
import {AuthService} from '../../../Services/BuissnessLogic/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InteliRouteService} from '../../../Services/inteli-route.service';
import {ImageService} from '../../../Services/image.service';
import {SeoService} from '../../../Services/SEO/seo.service';

@Component({
    selector: 'app-generation-page',
    templateUrl: './generation-page.component.html',
    styleUrl: './generation-page.component.scss'
})
export class GenerationPageComponent implements OnInit {
    max_ammount = 10000;
    step = 1000;
    selected_row_count = 1000;
    selectedIndustry = "";
    selectedRegion = "";
    industry_selected:boolean = false;
    region_selected:boolean = false;


    constructor(
        private checkout: CheckoutService,
        private auth: AuthService,
        private router: Router,
        private intRoute: InteliRouteService,
        private color: ColorScssService,
        private seoService: SeoService,
    ) {
        color.resetColorScheme();
    }

    ngOnInit() {
        this.seoService.setMetaData("generateTablePage");
    }

    onQuantityChange(event: Event) {
        const input = event.target as HTMLInputElement;
        const value = parseInt(input.value, 10);
        this.selected_row_count = isNaN(value) || value < 1 ? 1 : value;
    }

    increment() {
        if ((this.selected_row_count + 100) > this.max_ammount) {
            return;
        }

        this.selected_row_count += this.step;
    }

    decrement() {
        if (this.selected_row_count > 100) {
            this.selected_row_count -= this.step;
        }
    }

    goToCheckOut(table_type: number) {
        const calc_price:number = this.pricing2[table_type].price

        let scrapingRequest: ScrapingRequest = {
            industry: this.selectedIndustry,
            region: this.selectedRegion,
            row_count: this.selected_row_count,
            note: '', // todo later?
            price: calc_price * this.selected_row_count,
            type: table_type
        };
        this.checkout.set_scraping_request(scrapingRequest);

        if (this.auth.isLoggedIn()) {
            this.router.navigate(["/checkout/new_table"]);
        } else {
            // set Route to Return to Path after Login / Register
            this.intRoute.store_next_route("/checkout/new_table");
            this.router.navigate(["/anmelden"]);
        }
    }

    onDropdownValueChanged_Industry(selectedValue: string) {
        console.log('Selected Industry:', selectedValue);
        this.selectedIndustry = selectedValue
        this.industry_selected = this.selectedIndustry != "";
    }

    onDropdownValueChanged_Region(selectedValue: string) {
        console.log('Selected Region:', selectedValue);
        this.selectedRegion = selectedValue
        this.region_selected = this.selectedRegion != "";
    }

    pricing2 = [
        {
            icon: 'rocket-ship',
            package: 'Basic',
            feature1: 'Firmen Name',
            feature2: 'Website',
            feature3: 'Telefon Nummer',
            feature4: 'Adresse',
            feature5: 'Postleizahl',
            feature6: '-',
            feature7: '-',
            price: 0.01,
            btn: 'purchase',
            id: 0
        },
        {
            icon: 'diamond',
            package: 'Medium',
            feature1: 'Firmen Name',
            feature2: 'Website',
            feature3: 'Telefon Nummer',
            feature4: 'Adresse',
            feature5: 'Postleizahl',
            feature6: 'Email Adressen',
            feature7: '-',
            price: 0.05,
            btn: 'purchase',
            id: 1
        },
        {
            icon: 'pie-chart',
            package: 'Business',
            feature1: 'Firmen Name',
            feature2: 'Website',
            feature3: 'Telefon Nummer',
            feature4: 'Adresse',
            feature5: 'Postleizahl',
            feature6: 'Email Adressen',
            feature7: 'Name Geschäftsführer',
            price: 0.10,
            btn: 'purchase',
            id: 2
        }
    ];

    pricingcarouselOptions2 = {
        items: 3,
        margin: 30,
        nav: false,
        autoplay: false,
        slideSpeed: 300,
        loop: false,
        mouseDrag: false,
        touchDrag: false,
        dots: false,
        responsive: {
            0: {
                items: 1,
                margin: 10
            },
            576: {
                items: 2,
                margin: 10
            },
            991: {
                items: 2,
                margin: 15
            },
            992: {
                items: 3,
                margin: 15
            },
            1000: {
                items: 3
            }
        }
    };
}
