import { Component } from '@angular/core';
import {AppModule} from '../../app.module';
import {ColorScssService} from '../../shared/service/color-scss.service';

@Component({
  selector: 'app-lead-geneation-page',
  templateUrl: './lead-geneation-page.component.html',
  styleUrl: './lead-geneation-page.component.scss'
})
export class LeadGeneationPageComponent {

  constructor(private color: ColorScssService)
  {
    this.color.resetColorScheme();
  }

}
